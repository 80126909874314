import { Component, OnInit } from '@angular/core';
import {ResultDataService} from '../../result/result.service';

@Component({
  selector: 'app-independencev3',
  templateUrl: './independencev3.component.html',
  styleUrls: ['./independencev3.component.scss']
})
export class Independencev3Component implements OnInit {
  storage = 'PV-without-storage';
  data;
  season;
  innerWidth;
  yearPercent;
  springPercent;
  summerPercent;
  autumnPercent;
  winterPercent;
  radius;
  titlefont;
  constructor(private  resultDataService: ResultDataService) { this.resultDataService.result.subscribe(res => {
    this.data = res as any;
  });
    this.radius = 55;
    this.titlefont = 65; }

  ngOnInit(): void {
    this.changeSeason();
  }
  changeSeason() {
    if (this.storage === 'PV-without-storage') {
      this.yearPercent = Math.round(this.data ?.Output.independance['year'])
      ;
      this.summerPercent = Math.round(this.data ?.Output.independance['summer']
      )
      ;
      this.springPercent = Math.round(this.data ?.Output.independance['spring']
      )
      ;
      this.autumnPercent = Math.round(this.data ?.Output.independance['autumn']
      )
      ;
      this.winterPercent = Math.round(this.data ?.Output.independance['winter']
      )
      ;
    } else {
      this.yearPercent = Math.round(this.data ?.Output.independanceWbatt['year']
      )
      ;
      this.summerPercent = Math.round(this.data ?.Output.independanceWbatt['summer']
      )
      ;
      this.springPercent = Math.round(this.data ?.Output.independanceWbatt['spring']
      )
      ;
      this.autumnPercent = Math.round(this.data ?.Output.independanceWbatt['autumn']
      )
      ;
      this.winterPercent = Math.round(this.data ?.Output.independanceWbatt['winter']
      )
      ;
    }
  }

}
