<div>
  <div class="d-flex head">
    <h4 class="step-heading">{{'Consumption.content' | translate}}</h4>
    <p class="float-right" style="color: gray; font-family: 'poppins';">STEP 7/7</p>
  </div>
  <p></p>
  <div class="spacer-1"></div>
  <div class="form-container">
    <div class="row form-group d-flex flex-column ">
      <div class="row">
        <label class="col-12 col-form-label">
          {{'Consumption.num-of-people' | translate}}
        </label>
      </div>
      <div class="row">
        <div class="col-12 col-form-label">
          <input type="number" class="form-control" [(ngModel)]="consumption.numPeople" (ngModelChange)="extraInput($event)"
                 placeholder="{{'Consumption.num-of-people-placeholder' | translate}}" (mousewheel)="$event.preventDefault()">
        </div>
      </div>
    </div>

    <div class="row form-group d-flex flex-column ">
      <div class="row">
        <label class="col-12 col-form-label ">
          {{'Consumption.state' | translate}}
        </label>
      </div>

      <div class="row">
        <div class="col-12 col-form-label">
          <button *ngIf="stateList.length < 1"  style="border: 1px solid gray; color: #007d32; background-color: ghostwhite"  class="btn btn-block btn-light text-center spinner-button" type="button" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button>
          <select *ngIf="stateList.length > 1" name="electricId" id="state" (change)="changeStateTariffDetails()" [ngModelOptions]="{standalone: true}"  [(ngModel)]="selectedState" class="form-control">
            <option [ngValue]="undefined"  hidden selected>{{'Consumption.state' | translate}}</option>
            <!--            <option  *ngIf="!stateList" [value]="0" hidden >select state</option>-->

            <option *ngFor="let state of stateList" [value]="state">{{state}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row form-group d-flex flex-column ">
      <div class="row">
        <label class="col-12 col-form-label">
          {{'Consumption.yearly-consumption-de' | translate}}
        </label>
      </div>
      <div class="row">
        <div class="col-12 col-form-label">
          <input type="number" class="form-control" [ngModelOptions]="{standalone: true}"
                 [(ngModel)]="consumption.monthlyEbill"
                 placeholder="{{'Consumption.yearly-consumption-de' | translate}}" (mousewheel)="$event.preventDefault()">
        </div>
      </div>
    </div>




    <div class="row">
      <div class="col-12  text-center" *ngIf="consumption.numPeople && consumption.monthlyEbill && selectedState">
        <button class="btn btn-primary w-100" (click)="submitForm()">{{'Continue' | translate}}</button>
      </div>
    </div>
  </div>
</div>
