import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Constants} from '../../app.constant';
import {HomeService} from '../../home/home.service';
import {ToastrService} from 'ngx-toastr';
import {ResultDataService, ResultService} from '../../result/result.service';
import {Router} from '@angular/router';
import {HouseConsumptionIn} from './house-consumption.model-in';

@Component({
  selector: 'app-house-consumption-in',
  templateUrl: './house-consumption-in.component.html',
  styleUrls: ['./house-consumption-in.component.scss']
})
export class HouseConsumptionInComponent implements OnInit, OnChanges {

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() consumptionStep;
  @Output() skip: EventEmitter<any> = new EventEmitter<any>();
  @Output() extraInput1: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedEprice: EventEmitter<any> = new EventEmitter<any>();
  @Input() public consumption: HouseConsumptionIn;
  @Input() leadId: string;
  @Input() yearly: string;
  @Input() airCondition: string;
  data;
  electricId ;
  electrics = [];
  edit = 0;
  getSupplier = false;
  selectedState;
  ePrice;
  nm;
  pvTariff;
  ePriceObj: any;
  nmObj: any;
  pvTariffObj;
  stateList = [];
  // tariffList = [];
  hi;
  success;
  email;
  constructor(private appConstants: Constants,
              private homeService: HomeService,
              private  toastr: ToastrService,
              private resultDataService: ResultDataService,
              private  resultService: ResultService,
              private router: Router,
              private cdr: ChangeDetectorRef) {
    this.appConstants.email.subscribe(value => {
      this.email = value as string;
    });
    this.getSupplier = false;
    this.consumption = new HouseConsumptionIn();
    this.consumption.numPeople = 2;
  }

  ngOnInit(): void {
    this.extraInput(event);
    this.electrics = [];
    // this.extraInput(event);
    this.getState();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.leadId && this.leadId !== '' && this.yearly && this.yearly !== '') {
      this.changeStateTariffDetails();
    }
  }


  public submitForm(): void {
    if (this.consumption.numPeople && this.consumption.monthlyEbill) {
      this.uploadYearlyConsumption();
      this.onSubmit.emit({
        value: this.success
      });
    } else {
      this.toastr.warning('Please enter the details.');
    }
  }

  public skip1() {
    this.skip.emit({
      value: this.consumption
    });
  }

  public extraInput(event): void {
    this.extraInput1.emit({
      value: this.consumption
    });
  }



  getState() {
    this.homeService.getState(this.leadId, 'eprice').subscribe(res => {
      const response = res as any;
      for (const state of response.states ) {
        this.stateList.push(state);
      }
      this.ePriceObj = {
        ...response.eprice
      };
      this.nmObj = {
        ...response.nm
      };
      this.pvTariffObj = {
        ...response.pvtariff
      };
      console.log(this.ePriceObj);
      this.stateList.sort();
    }, error => {
      this.toastr.error('State data not found', 'Oops');
    });
  }

  changeStateTariffDetails() {
    localStorage.setItem('state', this.selectedState);
    this.ePrice = this.ePriceObj[this.selectedState];
    this.nm = this.nmObj[this.selectedState];
    this.pvTariff = this.pvTariffObj[this.selectedState];
    this.electricId = 0;
    console.log(this.nm, this.pvTariff);
    this.sendEprice(this.ePrice);
  }
  sendEprice(ePrice): void {
    this.selectedEprice.emit({
      ePrice
    });
  }
  yearlyEnergyConsumtion() {
    if (this.airCondition['noOfAirCondition'] === 0) {
      this.consumption.YearlyElecRequired = Math.round((Math.round(567 * this.consumption.numPeople + 2116.8) * 0.6) / 12);
    } else {
      this.consumption.YearlyElecRequired = Math.round((Math.round(1097 * this.consumption.numPeople + 2614.1) * 0.6) / 12);
    }
  }
  ////////
  uploadYearlyConsumption() {
    this.homeService.postStateTariffDetails(this.leadId, 'upload', {
      eprice: this.ePrice,
      state: this.selectedState,
      ebillmonthly: this.consumption.monthlyEbill,
      nm: this.nm,

    }).subscribe( res => {
      const response = res as any;
      console.log(response);
      this.getResult();
    }, error => {
      this.toastr.warning('Yearly Electric not updated Please try again', 'Oops');
    });
  }
  getResult() {
    this.resultService.getResult().subscribe(res => {
      this.data = res as any;
      this.router.navigate(['resultIn']);
      if (this.data.status) {
      }
      this.resultDataService.setResult(this.data);
    }, error => {
      this.toastr.warning('Something went wrong try again', 'Oops');
    });
  }

}
