import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-heaterv2',
  templateUrl: './heaterv2.component.html',
  styleUrls: ['./heaterv2.component.scss']
})
export class Heaterv2Component implements OnInit {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedData: EventEmitter<any> = new EventEmitter<any>();
  selectedHeaterEquipment;
  constructor(private toastr: ToastrService) { }

  ngOnInit(): void {
  }
  selectOption(value) {
    if (this.selectedHeaterEquipment === value) {
      this.selectedHeaterEquipment = '';
    } else {
      this.selectedHeaterEquipment = value;
    }
  }
  submitForm() {
    if (this.selectedHeaterEquipment) {
      this.onSubmit.emit({
        value: 'Heater'
      });
      this.selectedData.emit({
        value: this.selectedHeaterEquipment
      });
    } else {
      this.toastr.warning('Please select an option first!');
    }
  }
}
