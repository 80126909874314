<section style="margin-top: 8rem;    max-height: 850px;
    overflow-y: auto;">
  <div class="container justify-content-center text-center">
    <div class="row  p-3">
      <div class="col "><span class="dtext">{{'alignment.header' | translate}}</span></div>
    </div>
    <div class="row pt-3 heading ">
      <div class="col "><span class="htext">{{'alignment.heading' | translate}}</span>
      </div>
    </div>
    <div class="row px-5">
      <div class="col"><span class="ptext">{{'alignment.statement' | translate}}</span></div>
    </div>



    <div class="row desktop mt-5 mb-3">
      <div class="col-6 p-3 d-flex justify-content-end">
        <div class="house-circle" id='house-circle' (touchmove)="changeRotationTouch($event)"
          (mousemove)="changeRotation($event)" (touchend)="endDrag($event)" (mouseup)="endDrag($event)"
          [ngStyle]="{transform:'rotate('  +  (-alignment) + 'deg)'}">
          <div class="vertical-line"></div>
          <!--                      <div class="circle-dot" ></div>-->
          <div class="horizontal-line"></div>
          <!--          <span class="arrow arrow-right h-pointer icon-chevron"></span>-->
          <!--          <span class="arrow arrow-left h-pointer icon-chevron"></span>-->
          <!--                      <div class="circle-dot" id="arrow" (mousedown)="startDrag($event)" (touchstart)="startDrag($event)"></div>-->
          <img src="../../assets/Polygon%202.png" class="circle-dot" id="arrow" (mousedown)="startDrag($event)"
            (touchstart)="startDrag($event)">
          <div class="house-image roofimg" [innerHTML]="image">
          </div>
        </div>
      </div>
      <div class="col-6 p-3 d-flex justify-content-start">
        <img [src]="this.mapScreenShot" class="img-fluid screenS ">
      </div>
    </div>
    <div class="row mobile mt-5 mb-3 ">
      <div class="col-12 p-3 d-flex justify-content-center ">
        <img [src]="this.mapScreenShot" class="img-fluid screenS ">
      </div>
      <div class="col-12 p-3 d-flex justify-content-center ">
        <div class="house-circle" id='house-circle1' (touchmove)="changeRotationTouch($event)"
          (mousemove)="changeRotation($event)" (touchend)="endDrag($event)" (mouseup)="endDrag($event)"
          [ngStyle]="{transform:'rotate('  +  (-alignment) + 'deg)'}">
          <div class="vertical-line"></div>
          <!--                      <div class="circle-dot" ></div>-->
          <div class="horizontal-line"></div>
          <!--          <span class="arrow arrow-right h-pointer icon-chevron"></span>-->
          <!--          <span class="arrow arrow-left h-pointer icon-chevron"></span>-->
          <!--                      <div class="circle-dot" id="arrow" (mousedown)="startDrag($event)" (touchstart)="startDrag($event)"></div>-->
          <img src="../../assets/Polygon%202.png" class="circle-dot" id="arrow-m" (mousedown)="startDrag($event)"
            (touchstart)="startDrag($event)">
          <div class="house-image roofimg" [innerHTML]="image">
          </div>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col">
        <kendo-slider class=" " [tickPlacement]="tickPlacement" [min]="-180" [max]="180" [smallStep]="1"
          [(ngModel)]="alignment" [showButtons]="showButtons">
        </kendo-slider>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12">
        <span>{{ alignment }} <span style="font-size: 20px">&#176;</span></span>
      </div>
    </div>

    <div class="mt-3">
      <button type="button" style="font-size: 17px" class="btn btn-secondary" (click)="submitForm()">{{'Continue' |
        translate}}</button>
    </div>

  </div>
  <div>
    <img style="width: 100%" src="assets/bgimg.png">
  </div>
</section>