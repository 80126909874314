import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-house-alignment-in',
  templateUrl: './house-alignment-in.component.html',
  styleUrls: ['./house-alignment-in.component.scss']
})
export class HouseAlignmentInComponent implements OnInit, OnChanges {

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  @Input() alignment = 0;


  @Input() roofType;
  public tickPlacement = 'none';
  image: SafeHtml;

  types = [];
  @Input() alignmentStep;
  dragX = 0;
  dragY = 0;
  isDraging = false;

  constructor(private sanitizer: DomSanitizer) {

  }

  mapScreenShot;

  ngOnInit(): void {
    this.types = [
      {
        key: 'gable',
        iconSvg: this.sanitizer.bypassSecurityTrustHtml('<img src="./assets/roof-type-top-view/gal.png">')
      },
      {
        key: 'pyramid',
        iconSvg: this.sanitizer.bypassSecurityTrustHtml('<img src="./assets/roof-type-top-view/pyramid.png">')

      },
      {
        key: 'hip',
        iconSvg: this.sanitizer.bypassSecurityTrustHtml('<img src="./assets/roof-type-top-view/hip.png">')
      },
      {
        key: 'flat',
        iconSvg: this.sanitizer.bypassSecurityTrustHtml('<svg xmlns="http://www.w3.org/2000/svg"  height="512" viewBox="0 0 144.705 120.618">' +
          '<path id="flat" d="M759.476,156.59l7,4.041v34.227a2,2,0,0,0,1,1.732l41.569,24a2,2,0,0,0,2,0l83.138-48a2,2,0,0,0,1-1.732V136.631l7-4.041a2,2,0,0,0,0-3.464l-49.569-28.619a2,2,0,0,0-2,0l-91.138,52.619A2,2,0,0,0,759.476,156.59Zm92.138-52.041,45.569,26.309-87.138,50.309-45.569-26.309Zm-81.138,58.392,37.569,21.69v30.763L770.476,193.7Zm41.569,21.69,79.138-45.69V169.7l-79.138,45.691Z" transform="translate(-758.477 -100.239)"/>' +
          '</svg>')
      },
      {
        key: 'shed',
        iconSvg: this.sanitizer.bypassSecurityTrustHtml('<img src="./assets/roof-type-top-view/shed.png">')
      }
    ]
    ;

    this.types.forEach(item => {
      if (this.roofType === item.key) {
        this.image = item.iconSvg;
      }
    });
    //
    this.mapScreenShot = localStorage.getItem('mapScreenShotURL');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.types.forEach(item => {
      if (changes.roofType.currentValue === item.key) {
        this.image = item.iconSvg;
        this.alignment = 0;
      }
    });
  }

  getCenter(element) {
    const {left, top, width, height} = element.getBoundingClientRect();
    return {x: left + width / 2, y: top + height / 2};
  }

  changeRotation(evt) {
    // var img = $('.house-circle');
    var img = document.getElementById('house-circle');
    var pwBox = img.getBoundingClientRect();
    // var offset = img.offset();
    if (this.isDraging) {
      var center_x = (pwBox.left + pwBox.right) / 2;
      var center_y = (pwBox.top + pwBox.bottom) / 2;
      // var center_x = (offset.left) + (img.width() / 2);
      // var center_y = (offset.top) + (img.height() / 2);
      var mouse_x = evt.pageX;
      var mouse_y = evt.pageY;
      var radians = Math.atan2(mouse_x - center_x, mouse_y - center_y);
      var degree = (radians * (180 / Math.PI)) ;
      // var rotateCSS = 'rotate(' + (degree + 170) + 'deg)';
      this.alignment = Math.round(degree);
    }
  }

  changeRotationTouch(evt) {
    // var img = $('.house-circle');
    var img = document.getElementById('house-circle');
    var pwBox = img.getBoundingClientRect();
    // var offset = img.offset();
    if (this.isDraging) {
      var center_x = (pwBox.left + pwBox.right) / 2;
      var center_y = (pwBox.top + pwBox.bottom) / 2;
      // var center_x = (offset.left) + (img.width() / 2);
      // var center_y = (offset.top) + (img.height() / 2);
      var mouse_x = evt.touches[0].pageX;
      var mouse_y = evt.touches[0].pageY;
      var radians = Math.atan2(mouse_x - center_x, mouse_y - center_y);
      var degree = (radians * (180 / Math.PI)) ;
      // var rotateCSS = 'rotate(' + (degree + 170) + 'deg)';
      this.alignment = Math.round(degree);
    }
  }

  endDrag(event) {
    this.isDraging = false;
  }

  startDrag(event) {
    this.isDraging = true;
  }

  public submitForm(): void {
    this.onSubmit.emit({
      alignment: this.alignment
    });
  }
}
