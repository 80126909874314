import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-house-heater-in',
  templateUrl: './house-heater-in.component.html',
  styleUrls: ['./house-heater-in.component.scss']
})
export class HouseHeaterInComponent implements OnInit {

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() heaterStep;
  selectedHeaterEquipment;

  constructor() {
  }

  ngOnInit(): void {
  }

  public submitForm(): void {
    this.onSubmit.emit({
      value: this.selectedHeaterEquipment
    });
  }


  selectOption(value) {
    this.selectedHeaterEquipment = value;
  }

}
