import { Component, OnInit } from '@angular/core';
import {ResultDataService} from '../../result/result.service';

@Component({
  selector: 'app-sustainabilityv3',
  templateUrl: './sustainabilityv3.component.html',
  styleUrls: ['./sustainabilityv3.component.scss']
})
export class Sustainabilityv3Component implements OnInit {
  leadId;
  storage = 'PV-without-storage';
  data;
  trees;
  autoKM;
  co2;
  constructor(    private resultDataService: ResultDataService,) {
    this.resultDataService.result.subscribe(res => {
      this.data = res as any;
    });
  }

  ngOnInit(): void {
    this.changeSeason();
  }
  changeSeason() {
    if (this.storage === 'PV-without-storage') {
      this.co2 = Math.round(this.data?.Output.CO2redpercentt);
      this.autoKM = Math.round(this.data?.Output.Autokm);
      this.trees = Math.round(this.data?.Output.trees);

    } else {
      this.co2 = Math.round(this.data?.Output.CO2redpercentt);
      this.autoKM = Math.round(this.data?.Output.Autokm);
      this.trees = Math.round(this.data?.Output.trees);

    }
  }
}
