import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {Observable} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {ApiEndPoints, Configuration, Constants} from '../../app.constant';
@Injectable({
  providedIn: 'root'
})
export class HomeService {
  httpOptions;

  accessToken;
  leadId;
  constructor(
    private http: HttpClient,
    private apiEndPoints: ApiEndPoints,
    private appConstants: Constants,
    private _cookieService: CookieService,
    private configuration: Configuration,
    private authService: AuthService
  ) {

    this.appConstants.accessToken.subscribe(next => {
      this.accessToken = next as string;
      console.log(this.accessToken)
      this.httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          // Authorization: 'Bearer ' + this.accessToken
        })
      };
    });

    this.appConstants.leadId.subscribe(next => {
      this.leadId = next;
    });
  }

  makeNewLead(email) {
    console.log(this.httpOptions);
    return this.http.post(this.apiEndPoints.MAKE_NEW_LEAD, email, this.httpOptions);
  }

  getCustomerInfo(data) {
    return this.http.post(this.apiEndPoints.GET_CUSTOMER_INFO, JSON.stringify(data), this.httpOptions);
  }


  getAreaLocation(data) {
    return this.http.post(this.apiEndPoints.AREA_LOCATION, JSON.stringify(data), this.httpOptions );
  }

  updateroofArea(data) {
    return this.http.post(this.apiEndPoints.ROOFTYPE, JSON.stringify(data), this.httpOptions);
  }

  checkAreaLoaction(area) {
    return this.http.post(this.apiEndPoints.CHECK_AREA_LOCATION, area, this.httpOptions);
  }

  updatePriority(data) {
    return this.http.post(this.apiEndPoints.UPDATE_SOLAR_PRIORITY, JSON.stringify(data), this.httpOptions);
  }

  updateContactInformations(data) {
    return this.http.post(this.apiEndPoints.CONTACT_DIALOG, JSON.stringify(data), this.httpOptions);
  }

  uploadExtraInput(data) {
    return this.http.post(this.apiEndPoints.UPLOAD_EXTRA_INPUT, JSON.stringify(data), this.httpOptions);
  }

  uploadYearlyElectric(data) {
    return this.http.post(this.apiEndPoints.UPLOAD_YEARLY_ELECTRIC, JSON.stringify(data), this.httpOptions);
  }

  // uploadElectricId(data) {
  //   return this.http.post(this.apiEndPoints.UPLOAD_ELECTRIC_ID, JSON.stringify(data), this.httpOptions)
  // }

  uploadMannulaArea(data) {
    return this.http.post(this.apiEndPoints.UPLOAD_MANNUAL_AREA, JSON.stringify(data), this.httpOptions);
  }

  getElectricSupplier(data) {
    return this.http.post(this.apiEndPoints.GET_ELECTRIC_SUPPLIER, JSON.stringify(data), this.httpOptions);
  }
  public getState(leadID, ePrice) {
    return this.http.get(`${this.apiEndPoints.GET_STATE}?lead_id=${leadID}&action=${ePrice}`, this.httpOptions);
  }
  postStateTariffDetails(leadID , upload,  data) {
    return this.http.post(`${this.apiEndPoints.POST_STATE_SUPPLIER_DETAILS}?lead_id=${leadID}&action=${upload}`, data, this.httpOptions);
  }
  uploadElectricId(data) {
    return this.http.post(this.apiEndPoints.UPLOAD_ELECTRIC_ID, JSON.stringify(data), this.httpOptions);
  }

  public checkOrigin(data) {
    return this.http.post(this.apiEndPoints.CHECK_ORIGIN, JSON.stringify(data), this.httpOptions);
  }

  public getLanguage() {
    return localStorage.getItem('lang');
  }


  public setLanguage(language) {
    localStorage.setItem('lang', language);
  }


  public login<T>(username, password): Observable<any> {
    const authorizationData = 'Basic ' + btoa(username + ':' + password);
    this.appConstants.setToken(authorizationData);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.apiEndPoints.LOGIN_REQUEST, httpOptions);
  }

  public loginPreProd<T>(username, password): Observable<any> {
    const authorizationData = 'Basic ' + btoa(username + ':' + password);
    this.appConstants.setTokenPreProd(authorizationData);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.apiEndPoints.LOGIN_REQUEST_PRE_PROD, httpOptions);
  }

  public getTokenForImage<T>(username): Observable<any> {
    const password = 'default123';
    const authorizationData = 'Basic ' + btoa(username + ':' + password);
    localStorage.setItem('imageAuth', authorizationData);
    const httpOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: authorizationData
      })
    };
    return this.http.post<any>(this.apiEndPoints.AUTH_GOOGLE_CLOUD,  httpOption);
  }

  public uploadImage<t>(name): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const url = 'https://storage.googleapis.com/upload/storage/v1/b/solbucket/o?uploadType=media&name=CalcLeads/images/Uid_info@lead.com_Lid_'+this.leadId+'_screenshot.png';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'image/png',
        Authorization: 'asdas'
      })
    };
    return this.http.post<any>(url + name,  httpOptions);
  }

}
