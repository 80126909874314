import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {GooglePlaceDirective} from "ngx-google-places-autocomplete";
import {ToastrService} from "ngx-toastr";
import {HomeService} from "../home/home.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {DomSanitizer} from "@angular/platform-browser";
import {Address} from "ngx-google-places-autocomplete/objects/address";
import {HttpClient} from "@angular/common/http";
import {Location} from "@angular/common";
import {Constants} from '../../app.constant';

@Component({
  selector: 'app-addressv2',
  templateUrl: './addressv2.component.html',
  styleUrls: ['./addressv2.component.scss']
})
export class Addressv2Component implements OnInit {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSubmit1: EventEmitter<any> = new EventEmitter<any>();
  // @Input() rfunction;
  lang;
  lat;
  data;
  place = false;
  searchLocation;
  accessToken;
  location1;
  countryCode: string;
  input = document.getElementsByClassName('pac-item');
  convertLang;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  options = {
    type: [],
    componentRestrictions: {country: 'DE'}
  };

  constructor(private appConstants: Constants,
              private toastr: ToastrService,
              private homeService: HomeService,
              private sanitizer: DomSanitizer,
              private route: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private http: HttpClient,
              private locations: Location
  ) {
    this.appConstants.accessToken.subscribe(value => {
      this.accessToken = value;
    });
    this.convertLang = this.route.snapshot.queryParamMap.get('lang');
    localStorage.setItem('lang', this.route.snapshot.queryParamMap.get('lang'));
  }

  ngOnInit(): void {
    this.checkOrigin();
    this.convertLanguage();
  }
  checkOrigin() {
    let origin;
    const data = {
      origin: ''
    };
    if (this.route.snapshot.queryParams?.origin) {
      data.origin = this.route.snapshot.queryParams?.origin;
    } else if (this.locations['_platformLocation'].location.origin.includes('solextron')) {
      data.origin = 'https://solcalc.solextron.com';
    } else {
      data.origin = '';
    }
    localStorage.setItem('origin', data.origin);

    this.homeService.checkOrigin(data).subscribe(res => {
      const accessToken = res['access_token'];
      this.appConstants.setToken(accessToken);
      this.appConstants.setEmail(res['leadusers']);
    }, error => {
      if (error && error && error.message) {
        this.router.navigate(['service-error']);
      }
    });
  }


  convertLanguage() {
    if (this.convertLang === null || this.convertLang === '') {
      localStorage.setItem('lang', 'de');
      this.translate.use(this.homeService.getLanguage());
    } else {
      this.translate.use(this.convertLang);
    }
  }

  checkSearchedLocation(e) {
    console.log(e, ( /^\s*$/.test(e)));
    if (  /^\s*$/.test(e) === true) {
      return false;
    } else {
      return true;
    }
  }
  public callGeocode<T>() {
    // tslint:disable-next-line:max-line-length
    const api = `https://maps.googleapis.com/maps/api/geocode/json?address=${this.searchLocation}&key=AIzaSyA1uefY3TIXavmMXW0qgHNLn9a8vcE_804`;
    this.http.get(api).subscribe(res => {
      const response = res as any;
      const address = response.results[0];
      console.log(address.formatted_address, this.searchLocation);

      for (var i = 0; i < address.address_components.length; i++) {
        for (var j = 0; j < address.address_components[i].types.length; j++) {
          if (address.address_components[i].types[j] === 'country') {
            this.countryCode = address.address_components[i].short_name
          }
        }
      }
      if (this.countryCode !== 'CH') {
        this.place = false;
        return false;
      }
      this.place = false;
      this.lang = address.geometry.location.lng;
      this.lat = address.geometry.location.lat;
      this.searchLocation = address.formatted_address;
      localStorage.setItem('address', this.searchLocation);
      this.appConstants.setLocation(this.searchLocation);
    });
  }



  public AddressChange(address: Address) {
    console.log(address, address.geometry, address.geometry.location)
    this.place = false;
    this.lang = address.geometry.location.lng();
    localStorage.setItem('latitude', this.lang);
    this.lat = address.geometry.location.lat();
    localStorage.setItem('longitude', this.lat);
    this.location1 = address.formatted_address;
    this.searchLocation = address.formatted_address;
    this.appConstants.setReload(false);
    localStorage.setItem('address', this.searchLocation);
    this.appConstants.setLocation(this.searchLocation);
    // console.log(this.lat, this.lang, this.location1, 'address data');
  }

  location() {
    if (this.lang === '' || this.lat === '' || this.lang === undefined || this.lat === undefined) {
      this.toastr.warning('Please select an address first');
      return false;
    }
    if (this.searchLocation === '' || this.searchLocation === undefined ||  /^\s*$/.test(this.searchLocation) || this.checkSearchedLocation(event) === false) {
      this.toastr.warning('Please type your address ');
      return false;
    } else {
      return true;
    }
  }
  public locationLength() {
    if (this.input.length === 0) {
      this.place = true;
    } else {
      this.place = false;
    }
  }

  public submitForm(): void {
    if (this.location()) {
      this.onSubmit.emit({
        value: 'Location'
      });

    }
  }
}
