<div>
  <!--<h3 class="step-heading">{{'Roof-pitch.label' | translate}}</h3>-->
  <div class="d-flex head">
    <h3 class="step-heading">{{'Roof-pitch.label' | translate}}</h3>
    <p class="float-right" style="color: gray; font-family: 'poppins';">STEP 3/7</p>
  </div>
  <p>{{'Roof-pitch.content' | translate}}</p>
  <div class="spacer-2"></div>
  <div class="row remove-space">
    <div class="col-4">
      <div class="pitch-selector" (click)="selectPitch(0)" [ngClass]="{'selected': roofPitch==0}">
        <div class="angle-view">
          <div class="angle-line zero"></div>
        </div>
        <div class="angle-value">
          0&#176;
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="pitch-selector" (click)="selectPitch(30)" [ngClass]="{'selected': roofPitch==30}">
        <div class="angle-view">
          <div class="angle-line thirty left"></div>
          <div class="angle-line thirty right"></div>
        </div>
        <div class="angle-value">
          30&#176;
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="pitch-selector" (click)="selectPitch(45)" [ngClass]="{'selected': roofPitch==45}">
        <div class="angle-view">
          <div class="angle-line fourty-five left"></div>
          <div class="angle-line fourty-five right"></div>
        </div>
        <div class="angle-value">
          45&#176;
        </div>
      </div>
    </div>
  </div>
  <div class="spacer-3"></div>
  <div class="form-container">
    <div class="row form-group ">
      <div class="col-12">
        <input type="number" class="form-control w-100" [(ngModel)]="roofPitch"
               (keyup)="pitchCheck()" (mousewheel)="$event.preventDefault()" placeholder="{{'Roof-pitch.placeholder' | translate}}">
      </div>
    </div>
    <div class="spacer-2"></div>
    <div class="text-center" *ngIf="this.roofPitch != null">
      <button class="btn btn-primary w-100" (click)="submitForm()">{{'Continue' | translate}}</button>
    </div>
  </div>
</div>
