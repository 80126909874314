<div class="container pt-5 pb-5 justify-content-center text-center">
  <div class="row  p-3">
    <div class="col "><span class="dtext">{{'alignment.header' | translate}}</span></div>
  </div>
  <div class="row pt-3 heading ">
    <div class="col "><span class="htext">{{'alignment.heading' | translate}}</span>
    </div>
  </div>
  <div class="row px-5">
    <div class="col"><span class="ptext">{{'alignment.statement' | translate}}</span></div>
  </div>


  <div class="row desktop mt-5 mb-3">
    <div class="col-6 p-3 d-flex justify-content-end">
      <div class="house-circle" id="house-circle" (touchmove)="changeRotationTouch($event)"
           (mousemove)="changeRotation($event)" (touchend)="endDrag($event)" (mouseup)="endDrag($event)"
           [ngStyle]="{transform:'rotate('  +  (-alignment) + 'deg)'}">
        <div class="vertical-line"></div>
        <!--<div class="circle-dot"></div>-->
        <div class="horizontal-line"></div>
        <span class="arrow arrow-right h-pointer icon-chevron"></span>
        <span class="arrow arrow-left h-pointer icon-chevron"></span>
        <!--<div class="circle-dot"></div>-->
        <img src="../../../assets/hexa.PNG" class="circle-dot" id="arrow" (mousedown)="startDrag($event)"
             (touchstart)="startDrag($event)">
        <div class="house-image" [innerHTML]="image">
        </div>
      </div>
    </div>
    <div class="col-6 p-3 d-flex justify-content-start">
      <img [src]="this.mapScreenShot" class="img-fluid screenS ">
    </div>
  </div>


  <div class="row mobile mt-5 mb-3 ">
    <div class="col-12 p-3 d-flex justify-content-center ">
      <img [src]="this.mapScreenShot" class="img-fluid screenS ">
    </div>
    <div class="col-12 p-3 d-flex justify-content-center ">
      <div class="house-circle" id="house-circle1" (touchmove)="changeRotationTouch($event)"
           (mousemove)="changeRotation($event)" (touchend)="endDrag($event)" (mouseup)="endDrag($event)"
           [ngStyle]="{transform:'rotate('  +  (-alignment) + 'deg)'}">
        <div class="vertical-line"></div>
        <!--<div class="circle-dot"></div>-->
        <div class="horizontal-line"></div>
        <span class="arrow arrow-right h-pointer icon-chevron"></span>
        <span class="arrow arrow-left h-pointer icon-chevron"></span>
        <!--<div class="circle-dot"></div>-->
        <img src="../../../assets/hexa.PNG" class="circle-dot" id="arrow-m" (mousedown)="startDrag($event)"
             (touchstart)="startDrag($event)">
        <div class="house-image" [innerHTML]="image">
        </div>
      </div>
    </div>

  </div>

  <!--    <div class="row mt-5 mb-3 mr-3">-->
  <!--      <div class="col-6 p-3 d-flex justify-content-end">-->
  <!--        <div class="house-circle" id='house-circle'-->
  <!--             (mousemove)="changeRotation($event)"  (mouseup)="endDrag($event)"-->
  <!--             [ngStyle]="{transform:'rotate('  +  (-alignment) + 'deg)'}">-->
  <!--          <div class="vertical-line"></div>-->
  <!--          &lt;!&ndash;            <div class="circle-dot" ></div>&ndash;&gt;-->
  <!--          <div class="horizontal-line"></div>-->
  <!--          <span class="arrow arrow-right h-pointer icon-chevron"></span>-->
  <!--          <span class="arrow arrow-left h-pointer icon-chevron"></span>-->
  <!--          &lt;!&ndash;            <div class="circle-dot" id="arrow" (mousedown)="startDrag($event)" (touchstart)="startDrag($event)"></div>&ndash;&gt;-->
  <!--          <img src="../../assets/hexa.PNG" class="circle-dot" id="arrow" (mousedown)="startDrag($event)">-->
  <!--          <div class="house-image">-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      <div class="col-6 p-3 d-flex">-->
  <!--        <img [src]="this.mapScreenShot" class="img-fluid w-50">-->
  <!--      </div>-->
  <!--    </div>-->

  {{ alignment }} <span style="font-size: 20px">&#176;</span>
  <div class="mt-3">
    <button type="button" style="font-size: 17px" class="btn btn-secondary"
            (click)="submitForm()">{{'Continue' | translate}}</button>
  </div>

</div>

