import {Component, OnInit, ViewChild} from '@angular/core';
import {GooglePlaceDirective} from "ngx-google-places-autocomplete";
import {Address} from "ngx-google-places-autocomplete/objects/address";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {HomeService} from "../home/home.service";
import {FormDataModel} from "../home/home.model";
import {DomSanitizer} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {NgxSpinnerService} from "ngx-spinner";
import {DialogContactComponent} from "../dialog-contact/dialog-contact.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import { Location} from '@angular/common';
import {Constants} from '../../app.constant';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  options = {
    types: [],
    componentRestrictions: {country: 'DE'}
  };
  lang;
  lat;
  data;
  place = false;
  input = document.getElementsByClassName('pac-item');
  input1 = document.getElementsByClassName('map-search');
  searchLocation;
  accessToken;
  location1;
  countryCode: string;
  isAddressEnter = false;
 convertLang;
  bsModalRef: BsModalRef;

  constructor(private router: Router,
              private toastr: ToastrService,
              private homeService: HomeService,
              private appConstants: Constants,
              private sanitizer: DomSanitizer,
              private translate: TranslateService,
              private route: ActivatedRoute,
              private http: HttpClient,
              private loader: NgxSpinnerService,
              private modalService: BsModalService,
              private locations: Location) {
    this.appConstants.accessToken.subscribe(value => {
      this.accessToken = value as string;
    });
    this.appConstants.lang = this.route.snapshot.queryParamMap.get('lang');
    localStorage.setItem('lang', this.route.snapshot.queryParamMap.get('lang') );
    this.convertLang = this.route.snapshot.queryParamMap.get('lang');

  }

  ngOnInit() {
    // this.login();
    // this.loginPreProd()
    this.convertLanguage();
    this.checkOrigin();
  }

  checkOrigin() {
    let origin;
    const data = {
      origin: ''
    };
    if (this.route.snapshot.queryParams?.origin) {
      data.origin = this.route.snapshot.queryParams?.origin;
    } else if (this.locations['_platformLocation'].location.origin.includes('solextron')) {
      data.origin = 'https://solcalc.solextron.com';
    } else {
      data.origin = '';
    }
    localStorage.setItem('origin', data.origin);

    this.homeService.checkOrigin(data).subscribe(res => {
      this.appConstants.setToken(res['access_token']);
      this.appConstants.setEmail(res['leadusers']);
    }, error => {
      if (error && error && error.message) {
        this.router.navigate(['de/service-error']);
      }
    });
  }

  processChange(e) {
    if (e.target.tagName === 'INPUT') {

    } else {
      this.loader.hide();
      this.callGeocode();
    }
  }


  public callGeocode<T>() {
    console.log('geocode');
    const api = `https://maps.googleapis.com/maps/api/geocode/json?address=${this.searchLocation}&key=AIzaSyA1uefY3TIXavmMXW0qgHNLn9a8vcE_804`;
    this.http.get(api).subscribe(res => {
      const response = res as any;
      const address = response.results[0];
      for (var i = 0; i < address.address_components.length; i++) {
        for (var j = 0; j < address.address_components[i].types.length; j++) {
          if (address.address_components[i].types[j] === 'country') {
            this.countryCode = address.address_components[i].short_name
          }
        }
      }
      if (this.countryCode !== 'CH') {
        this.place = false;
        return false;
      }
      this.place = false;
      this.lang = address.geometry.location.lng;
      this.lat = address.geometry.location.lat;
      this.searchLocation = address.formatted_address;
      localStorage.setItem('address', this.searchLocation);
      this.appConstants.setLocation(this.searchLocation);
    })
  }

  convertLanguage() {
    if (this.convertLang === null || this.convertLang === '') {
      localStorage.setItem('lang', 'de');
      this.translate.use(this.homeService.getLanguage());
    } else {
      this.translate.use(this.convertLang);
    }
  }

  public handleAddressChange(address: Address) {
    if (address && address.geometry && address.geometry.location) {
      this.place = false;
      this.lang = address.geometry.location.lng();
      this.lat = address.geometry.location.lat();
      this.appConstants.setReload(false)
      this.location1 = address.formatted_address;
      this.searchLocation = address.formatted_address;
      localStorage.setItem('address', this.searchLocation);
      this.appConstants.setLocation(this.searchLocation);
    }
  }

  homePage() {
    if (this.lat === '' || this.lang === '' || this.lang === undefined || this.lat === undefined) {
      this.toastr.warning('Please select address first');
      return false;
    }
    if (this.searchLocation === undefined || this.searchLocation === '' || /^\s*$/.test(this.searchLocation) || this.checkSearchedLocation(event) === false) {
      this.toastr.warning('Please write your address');
      return false;
    } else {
      this.router.navigate(['de/home'], {queryParams: {lon: this.lang, lat: this.lat}});
      return true;
    }
  }


  public location() {
    console.log('location provess')
    if (this.input.length === 0 && this.checkSearchedLocation(event)) {
      this.place = true;
    } else {
      this.place = false;
    }
  }
  checkSearchedLocation(e) {
    if ( /^\s*$/.test(e) === true) {
      return false;
    } else {
      return true;
    }
  }
  login() {
    this.homeService.login('abhinandan.gupta@symbtechnologies.com', 'password').subscribe(response => {
        this.appConstants.setToken(response.access_token);
      },
      response => {
        if (response && response.error && response.error.error) {
          this.toastr.error(response.error.error, 'Opps!');
        } else {
          this.toastr.error('Unable to complete your request, please try after sometime.', 'Opps!');
        }
      });
  }

  loginPreProd() {
    this.homeService.loginPreProd('abhinandan.gupta@symbtechnologies.com', 'password').subscribe(response => {
        console.log(response, 'token for preprod');
        this.appConstants.setTokenPreProd(response.access_token);
      },
      response => {
        if (response && response.error && response.error.error) {
          this.toastr.error(response.error.error, 'Opps!');
        } else {
          this.toastr.error('Unable to complete your request, please try after sometime.', 'Opps!');
        }
      });
  }


}
