<div *ngIf="electrics">
  <div>

    <h4 class="step-heading">{{'Supplier.content' | translate}}</h4>
    <div class="spacer-3"></div>

    <div class="container-fluid">
      <div class="row mb-4">
        <div class="col-md-4 mt-2">
          {{'Supplier.label1' | translate}}
        </div>

        <div class="col-md-8 mb-3">
          <select *ngIf='electrics' name="edit" [(ngModel)]="electricId" class="form-control"
                  (change)="uploadElectricId()">
            <option [ngValue]="null" disabled selected>Select supplier</option>
            <option *ngFor="let electric of electrics" [value]="electric.elecID">{{ electric.Name }}</option>
          </select>
        </div>
      </div>
    </div>

  </div>
</div>
<div *ngIf="!electrics">
  <div class="h4">
    {{'Supplier.sorry-msg' | translate}}
  </div>
</div>
