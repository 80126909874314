import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {TermsAndConditionComponent} from './terms-and-condition/terms-and-condition.component';
// import {MetaModule} from '@ngx-meta/core';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {HousePitchComponent} from './house-pitch/house-pitch.component';
import {HouseAlignmentComponent} from './house-alignment/house-alignment.component';
import {HouseHotWaterComponent} from './house-hot-water/house-hot-water.component';
import {HouseHeaterComponent} from './house-heater/house-heater.component';
import {HouseConsumptionComponent} from './house-consumption/house-consumption.component';
import {HouseRoofAreaComponent} from './house-roof-area/house-roof-area.component';
import {HouseRoofTypeComponent} from './house-roof-type/house-roof-type.component';
import {ApiEndPoints, Configuration, Constants} from './app.constant';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {ResultModule} from './result/result.module';
import {ROUND_PROGRESS_DEFAULTS, RoundProgressModule} from 'angular-svg-round-progressbar';
import {ChartsModule} from 'ng2-charts';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import {MatRadioModule} from '@angular/material/radio';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {UiSwitchModule} from 'ngx-toggle-switch';
import {HousePriorityComponent} from './house-priority/house-priority.component';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DialogContactComponent} from './dialog-contact/dialog-contact.component';
import {HouseEnergyComponent} from './house-energy/house-energy.component';
import {MatSelectModule} from '@angular/material/select';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatSliderModule} from '@angular/material/slider';
import {MatOptionModule} from '@angular/material/core';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CookieService} from 'ngx-cookie-service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TokenInterceptor} from './auth/token.interceptor';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {NgxSpinnerModule} from 'ngx-spinner';
import {LoaderInterceptor} from './loader/loader.interceptor';
import {NgSelectModule} from '@ng-select/ng-select';
import {AddressComponent} from './address/address.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {MatTabsModule} from '@angular/material/tabs';
import {NewResultComponent} from './new-result/new-result.component';
import {ResultConsumptionComponent} from './result-consumption/result-consumption.component';
import {ResultIndependenceComponent} from './result-independence/result-independence.component';
import {ResultHowFutherComponent} from './result-how-futher/result-how-futher.component';
import {ResultTypicalDayComponent} from './result-typical-day/result-typical-day.component';
import {ResultSustainComponent} from './result-sustain/result-sustain.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {HomeLocationComponent} from './home-location/home-location.component';
import {ResultFinancialComponent} from './result-financial/result-financial.component';
import {HomeAirConditionComponent} from './home-air-condition/home-air-condition.component';
import {AuthorizationInterceptor} from './auth/authorization.interceptor';
import {Roofareav3Component} from './roofareav3/roofareav3.component';
import {Housetypev3Component} from './housetypev3/housetypev3.component';
import {Roofpitchv3Component} from './roofpitchv3/roofpitchv3.component';
import {Orientationv3Component} from './orientationv3/orientationv3.component';
import {Hotwaterv3Component} from './hotwaterv3/hotwaterv3.component';
import {Heaterv3Component} from './heaterv3/heaterv3.component';
import {Consumptionv3Component} from './consumptionv3/consumptionv3.component';
import {Motivationv3Component} from './motivationv3/motivationv3.component';
import {Locationv3Component} from './locationv3/locationv3.component';
import {HeaderComponent} from './header/header.component';
import {Quotev3Component} from './quotev3/quotev3.component';
import {Independencev3Component} from './resultv3/independencev3/independencev3.component';
import {Resultv3Component} from './resultv3/resultv3.component';
import {TypicalDayv3Component} from './resultv3/typical-dayv3/typical-dayv3.component';
import {ResultConsumptionv3Component} from './resultv3/result-consumptionv3/result-consumptionv3.component';
import {Sustainabilityv3Component} from './resultv3/sustainabilityv3/sustainabilityv3.component';
import {Furtherv3Component} from './resultv3/furtherv3/furtherv3.component';
import {Financialv3Component} from './resultv3/financialv3/financialv3.component';
import {AddressInComponent} from './india/address-in/address-in.component';
import {HomeInComponent} from './india/home-in/home-in.component';
import {HouseRoofAreaInComponent} from './india/house-roof-area-in/house-roof-area-in.component';
import {HouseRoofTypeInComponent} from './india/house-roof-type-in/house-roof-type-in.component';
import {HousePitchInComponent} from './india/house-pitch-in/house-pitch-in.component';
import {HouseAlignmentInComponent} from './india/house-alignment-in/house-alignment-in.component';
import {HousePriorityInComponent} from './india/house-priority-in/house-priority-in.component';
import {HouseHotWaterInComponent} from './india/house-hot-water-in/house-hot-water-in.component';
import {HouseHeaterInComponent} from './india/house-heater-in/house-heater-in.component';
import {HomeAirConditionInComponent} from './india/home-air-condition-in/home-air-condition-in.component';
import {HouseConsumptionInComponent} from './india/house-consumption-in/house-consumption-in.component';
import {ResultInComponent} from './india/result-in/result-in.component';
import {ResultIndependenceInComponent} from './india/result-independence-in/result-independence-in.component';
import {ResultConsumptionInComponent} from './india/result-consumption-in/result-consumption-in.component';
import {ResultTypicalDayInComponent} from './india/result-typical-day-in/result-typical-day-in.component';
import {ResultFinancialInComponent} from './india/result-financial-in/result-financial-in.component';
import {ResultHowFurtherInComponent} from './india/result-how-further-in/result-how-further-in.component';
import {ResultSustainInComponent} from './india/result-sustain-in/result-sustain-in.component';
import {DeModule} from './de/de.module';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    HousePitchComponent,
    HouseAlignmentComponent,
    HouseHotWaterComponent,
    HouseHeaterComponent,
    HouseConsumptionComponent,
    HouseRoofAreaComponent,
    HouseRoofTypeComponent,
    HousePriorityComponent,
    DialogContactComponent,
    AddressComponent,
    HouseEnergyComponent,
    NewResultComponent,
    ResultConsumptionComponent,
    ResultIndependenceComponent,
    ResultHowFutherComponent,
    ResultTypicalDayComponent,
    ResultSustainComponent,
    HomeLocationComponent,
    ResultFinancialComponent,
    HomeAirConditionComponent,
    Roofareav3Component,
    Housetypev3Component,
    Roofpitchv3Component,
    Orientationv3Component,
    Hotwaterv3Component,
    Heaterv3Component,
    Consumptionv3Component,
    Motivationv3Component,
    Locationv3Component,
    HeaderComponent,
    Quotev3Component,
    Independencev3Component,
    Resultv3Component,
    TypicalDayv3Component,
    ResultConsumptionv3Component,
    Sustainabilityv3Component,
    Furtherv3Component,
    Financialv3Component,
    AddressInComponent,
    HomeInComponent,
    HouseRoofAreaInComponent,
    HouseRoofTypeInComponent,
    HousePitchInComponent,
    HouseAlignmentInComponent,
    HousePriorityInComponent,
    HouseHotWaterInComponent,
    HouseHeaterInComponent,
    HomeAirConditionInComponent,
    HouseConsumptionInComponent,
    ResultInComponent,
    ResultIndependenceInComponent,
    ResultConsumptionInComponent,
    ResultTypicalDayInComponent,
    ResultFinancialInComponent,
    ResultHowFurtherInComponent,
    ResultSustainInComponent
  ],
  imports: [
    DeModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    // MetaModule.forRoot(),
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    ModalModule.forRoot(),
    ResultModule,
    RoundProgressModule,
    ChartsModule,
    ResultModule,
    MatRadioModule,
    CarouselModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    UiSwitchModule,
    TabsModule.forRoot(),
    TabsModule.forRoot(),
    MatSelectModule,
    MatSliderModule,
    MatOptionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    DragDropModule,
    ProgressbarModule.forRoot(),
    NgxUiLoaderModule,
    NgxSpinnerModule,
    InputsModule,
    MatTabsModule,
    NgCircleProgressModule.forRoot(),
    BsDropdownModule.forRoot(),
    DropDownsModule,
    GooglePlaceModule,


  ],
  entryComponents: [
    DialogContactComponent,
    Quotev3Component
  ],
  providers: [
    ApiEndPoints,
    Configuration,
    BsModalRef,
    Constants,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
