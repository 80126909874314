// import {HouseRoofTypeModel} from "../housetypev3/HouseRoofType.model";

import {ConsumptionModel} from "../consumptionv2/Consumption.model";
import {HouseRoofTypeModel} from "../housetypev2/HouseRoofType.model";

class HeaderModel {
  email: string;
  token: any;
  leadId: string;
  roofArea: number;
  longitude: any;
  latitude: any;
  roofSurfaceNumber: number;
  roofType: HouseRoofTypeModel;
  roofPitch: number;
  alignment: number;
  consumption: ConsumptionModel;
  hotWaterType: string;
  heaterType: string;
  electric: number;
  location: any;
}
export {
  HeaderModel
};
