<section>
  <div class="container justify-content-center text-center">
    <div class="row mt-5 p-3">
      <div class="col "><span class="dtext">{{'sustainability.label1' | translate}}</span></div>
    </div>
    <div class="row pb-3 heading ">
      <div class="col p-3"><span class="htext">
      {{'sustainability.content' | translate}}</span>
      </div>
    </div>

    <!--cards-->
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg m-2 d-flex justify-content-center p-4" style="background-color: #E5E9EC">
        <div class="row align-content-center">
          <div class="col-3 p-4">
            <img src="assets/Path%203700.png" alt="pathimg">
          </div>
          <div class="col-9 p-4 caption text-center">
            <span>{{'sustainability.globe' | translate}} <b>{{autoKM}}</b>{{'sustainability.globe1' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg m-2 d-flex justify-content-center p-4" style="background-color: #E5E9EC">
        <div class="row align-content-center">
          <div class="col-3 p-4">
            <img src="assets/noun_foot%20print_1087838.png" alt="pathimg">
          </div>
          <div class="col-9 p-4 caption">
            <span>{{'sustainability.foot' | translate}} <b>{{co2}}</b>%.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg m-2 d-flex align-content-center justify-content-center p-4" style="background-color: #E5E9EC">
        <div class="row align-content-center">
          <div class="col-3 p-4">
            <img src="assets/noun_Tree_413107.png" alt="pathimg">
          </div>
          <div class="col-9 p-4 caption">
            <span>{{'sustainability.tree' | translate}} <b>{{trees}}</b> {{'sustainability.tree1' | translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
