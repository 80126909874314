import {AfterContentChecked, ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {HeaderModel} from './header.model';
import {HomeService} from '../home/home.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {Constants} from '../app.constant';
declare function uploadMapData(): any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterContentChecked  {
  public model = new HeaderModel();
  activeStep = 'Location';
  selectedV = true;
  // email: string;
  token;
  mail;
  areaEvent;
  roofTypeData;
  numSurface;
  roofPitch;
  lead;
  data;
  hotWater;
  Heater;
  title1
  isVisible = true;
  mapCondition = false;
  showLoader = false;
  i = 0;
  email;
  leadId;
  filtered;
  ePriceSelected;
  headArr = [  {title: 'Location', selected: false},
               {title: 'Roof Area', selected: false},
               {title: 'House Type', selected: false},
               {title: 'Roof Pitch', selected: false},
               {title: 'Orientation', selected: false},
               {title: 'Hot Water', selected: false},
               {title: 'Heater', selected: false},
               {title: 'Average Consumption', selected: false}
               // {title: 'Motivation'}
                ];

  constructor(private homeService: HomeService,
              private toastr: ToastrService,
              private loader: NgxSpinnerService,
              private Zone: NgZone,
              private translate: TranslateService,
              private cdr: ChangeDetectorRef,
              private appConstants: Constants,
              ) {
    // @ts-ignore
    window?.angularComponentRef  = { component: this, zone: Zone };
    this.model = new HeaderModel();

    this.appConstants.email.subscribe(value => {
      this.email = value as string;
    });

    this.appConstants.leadId.subscribe( value => {
      console.log(value,'LEADSSS');
      this.leadId = value as string; });
  }
  translateLang(heading) {
    return this.translate.instant('heading.' + heading);
  }

  ngOnInit(): void {}
  ngAfterContentChecked() {
    this.cdr.detectChanges();
    // this.uploadExtraInput()
  }
  stepChange(step) {
    this.filtered = this.headArr.filter((item) => item.title === step);
    if (this.filtered[0].selected ) {
    return this.activeStep = step;
    }

  }
  receiveData(data): void {
    this.model.email = data.email;
    this.model.token = data.token;
    this.model.leadId = data.lead;
    // this.model.longitude = data.lat;
    // console.log(this.model.longitude);
    this.model.location = data.location;
    this.model.roofPitch = data.pitch;
    this.model.alignment = data.alignAngle;
    // console.log(this.model.roofPitch);
    // console.log(this.model.email);
    // console.log(this.model.token);
    // console.log(this.model.leadId);
    // console.log(this.model.location);
    // this.mail = this.model.email;
    // console.log(this.mail);
  }
  receivedPitch(data) {
    this.roofPitch = data.pitch;
    console.log(this.roofPitch);
  }
  selectedData(rdata) {
    this.roofTypeData = rdata.value.key;
    this.numSurface = rdata.twoSideOccupy;
    console.log(this.roofTypeData);
    console.log(this.numSurface);
  }
  selectedDataHotWater(data) {
    this.hotWater = data.value;
    this.model.hotWaterType = data.value;
    console.log(this.hotWater);
  }
  selectedHeater(data) {
    this.model.heaterType = data.value;
    console.log(this.model.heaterType);
  }

  next(step) {
    // console.log(step, 'step');
    if (step === 'Location') {
      this.activeStep = 'Roof Area';
      this.isVisible = true;
      this.headArr[0].selected = true;
      console.log(this.headArr[0].selected);
    } else if (step === 'Roof Area') {
      uploadMapData();
      if (!this.mapCondition) {
        this.activeStep = 'House Type';
        this.isVisible = true;
        // this.headArr[0].selected = true;
        this.headArr[1].selected = true;
        console.log(this.headArr[0].selected, this.headArr[1].selected);
      } else {
        this.mapCondition = false;
      }
    } else if (step === 'House Type') {
      this.activeStep = 'Roof Pitch';
      this.isVisible = true;
      this.headArr[2].selected = true;
    }  else if (step === 'Roof Pitch') {
      this.activeStep = 'Orientation';
      this.isVisible = true;
      this.headArr[3].selected = true;
    } else if (step === 'Orientation') {
      this.activeStep = 'Hot Water';
      this.isVisible = true;
      this.headArr[4].selected = true;
    } else if (step === 'Hot Water') {
      this.activeStep = 'Heater';
      this.isVisible = true;
      this.headArr[5].selected = true;
    } else if (step === 'Heater') {
      this.activeStep = 'Average Consumption';
      this.isVisible = true;
      this.headArr[6].selected = true;
    } else if (step === 'Average Consumption') {
      // this.activeStep = 'Motivation';
      this.showLoader = true;
      this.isVisible = true;
      this.headArr[7].selected = true;
    } else {
      this.isVisible = true;
    }
    // else if (step === 'Motivation') {
    //   this.isVisible = true;
    // }
    }
  uploadExtraInput1(event) {
    this.model.consumption = event.value;
    this.homeService.uploadExtraInput({
      email: this.email,
      lead_id: this.leadId,
      info: {
        NumPeople: this.model.consumption.numPeople,
        Heating: this.model.heaterType,
        Water: this.model.hotWaterType,
        Type: 'Residential',
      }
    }).subscribe(res => {
      const response = res as any;
      this.model.consumption.YearlyElecRequired = response.energy;
      if (this.model.consumption.YearlyElecRequired && this.ePriceSelected) {
        this.changeMonthlyConsumption();
      }
      // this.uploadYearlyElectric();
    }, error => {
      this.toastr.warning('Something went Wrong Please try again', 'Oops');
    });
  }
  getEprice(event) {
    console.log(event, 'event');
    this.ePriceSelected = event.ePrice;
    this.changeMonthlyConsumption();
  }
  changeMonthlyConsumption() {
    if (this.model.consumption.YearlyElecRequired && this.ePriceSelected) {
      this.model.consumption.monthlyEbill = Math.round((this.model.consumption.YearlyElecRequired / 12) * this.ePriceSelected );
    }
  }
  checkAreaError() {
    // this.loader.hide();
    this.mapCondition = true;
    return this.mapCondition;
  }
  locationDetails() {
    // this.loader.show();
    // uploadMapData() ;
    // this.areaEvent = event;
  }
  // calledFromOutside(index) {
  //   this.loader.hide();
  //   console.log('outside-------');
  //   // this.checkArea(0, this.areaEvent);
  // }
  // checkArea(index, event) {
  //   this.homeService.checkAreaLoaction({
  //     email: this.email,
  //     lead_id: this.model.leadId,
  //     check: 'area'
  //   }).subscribe(res => {
  //       const response = res as any;
  //       this.area = Math.round(response.AreafromMap);
  //       this.onSubmit(index, event);
  //       this.toastr.success('Roof area uploaded successfully');
  //
  //     }, error => {
  //       // this.toastr.warning('Please first draw the area on the map', 'Oops');
  //     }
  //   );
  // }



}
