import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HomeComponent} from './home/home.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {TermsAndConditionComponent} from './terms-and-condition/terms-and-condition.component';
// import {MetaModule} from '@ngx-meta/core';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {HousePitchComponent} from './house-pitch/house-pitch.component';
import {HouseAlignmentComponent} from './house-alignment/house-alignment.component';
import {HouseHotWaterComponent} from './house-hot-water/house-hot-water.component';
import {HouseHeaterComponent} from './house-heater/house-heater.component';
import {HouseConsumptionComponent} from './house-consumption/house-consumption.component';
import {HouseRoofAreaComponent} from './house-roof-area/house-roof-area.component';
import {HouseRoofTypeComponent} from './house-roof-type/house-roof-type.component';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {ResultModule} from './result/result.module';
import { RoundProgressModule} from 'angular-svg-round-progressbar';
import {ChartsModule} from 'ng2-charts';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import {MatRadioModule} from '@angular/material/radio';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {UiSwitchModule} from 'ngx-toggle-switch';
import {HousePriorityComponent} from './house-priority/house-priority.component';
import {TabsModule} from 'ngx-bootstrap/tabs';
import { ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DialogContactComponent} from './dialog-contact/dialog-contact.component';
import {HouseEnergyComponent} from './house-energy/house-energy.component';
import {MatSelectModule} from '@angular/material/select';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MatSliderModule} from '@angular/material/slider';
import {MatOptionModule} from '@angular/material/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CookieService} from 'ngx-cookie-service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TokenInterceptor} from './auth/token.interceptor';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {NgxSpinnerModule} from 'ngx-spinner';
import {LoaderInterceptor} from './loader/loader.interceptor';
import {AddressComponent} from './address/address.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {MatTabsModule} from '@angular/material/tabs';
import {NewResultComponent} from './new-result/new-result.component';
import {ResultConsumptionComponent} from './result-consumption/result-consumption.component';
import {ResultIndependenceComponent} from './result-independence/result-independence.component';
import {ResultHowFutherComponent} from './result-how-futher/result-how-futher.component';
import {ResultTypicalDayComponent} from './result-typical-day/result-typical-day.component';
import {ResultSustainComponent} from './result-sustain/result-sustain.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {HomeLocationComponent} from './home-location/home-location.component';
import {ResultFinancialComponent} from './result-financial/result-financial.component';
import {AuthorizationInterceptor} from './auth/authorization.interceptor';
import {Homev2Component} from './homev2/homev2.component';
import {Addressv2Component} from './addressv2/addressv2.component';
import {Roofareav2Component} from './roofareav2/roofareav2.component';
import {Housetypev2Component} from './housetypev2/housetypev2.component';
import {Roofpitchv2Component} from './roofpitchv2/roofpitchv2.component';
import {Orientationv2Component} from './orientationv2/orientationv2.component';
import {Hotwaterv2Component} from './hotwaterv2/hotwaterv2.component';
import {Heaterv2Component} from './heaterv2/heaterv2.component';
import {Consumptionv2Component} from './consumptionv2/consumptionv2.component';
import {Quotev2Component} from './quotev2/quotev2.component';
import {Resultv2Component} from './resultv2/resultv2.component';
import {Independencev2Component} from './resultv2/independencev2/independencev2.component';
import {ResultConsumptionv2Component} from './resultv2/result-consumptionv2/result-consumptionv2.component';
import {Financialv2Component} from './resultv2/financialv2/financialv2.component';
import {Typicaldayv2Component} from './resultv2/typicaldayv2/typicaldayv2.component';
import {Sustainabilityv2Component} from './resultv2/sustainabilityv2/sustainabilityv2.component';
import {Furtherv2Component} from './resultv2/furtherv2/furtherv2.component';
import {ServiceErrorComponent} from './service-error/service-error.component';
import { DeComponent} from './de/de.component';
import {DeRoutingModule} from './de-routing.module';
import {CommonModule} from '@angular/common';
import { Homev3Component } from './v3/homev3/homev3.component';
import { Addressv3Component } from './v3/addressv3/addressv3.component';
import { Roofareav3Component } from './v3/roofareav3/roofareav3.component';
import { Housetypev3Component } from './v3/housetypev3/housetypev3.component';
import { Roofpitchv3Component } from './v3/roofpitchv3/roofpitchv3.component';
import { Orientationv3Component } from './v3/orientationv3/orientationv3.component';
import { Consumptionv3Component } from './v3/consumptionv3/consumptionv3.component';








export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    HomeComponent,
    PrivacyPolicyComponent,
    ServiceErrorComponent,
    TermsAndConditionComponent,
    HousePitchComponent,
    HouseAlignmentComponent,
    HouseHotWaterComponent,
    HouseHeaterComponent,
    HouseConsumptionComponent,
    HouseRoofAreaComponent,
    HouseRoofTypeComponent,
    HousePriorityComponent,
    DialogContactComponent,
    AddressComponent,
    HouseEnergyComponent,
    NewResultComponent,
    ResultConsumptionComponent,
    ResultIndependenceComponent,
    ResultHowFutherComponent,
    ResultTypicalDayComponent,
    ResultSustainComponent,
    HomeLocationComponent,
    ResultFinancialComponent,
    Homev2Component,
    Addressv2Component,
    Roofareav2Component,
    Housetypev2Component,
    Roofpitchv2Component,
    Orientationv2Component,
    Hotwaterv2Component,
    Heaterv2Component,
    Consumptionv2Component,
    Quotev2Component,
    Resultv2Component,
    Independencev2Component,
    ResultConsumptionv2Component,
    Financialv2Component,
    Typicaldayv2Component,
    Sustainabilityv2Component,
    Furtherv2Component,
    DeComponent,
    Homev3Component,
    Addressv3Component,
    Roofareav3Component,
    Housetypev3Component,
    Roofpitchv3Component,
    Orientationv3Component,
    Consumptionv3Component
  ],
  imports: [
    DeRoutingModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    // MetaModule.forRoot(),
    CommonModule,
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    ModalModule.forRoot(),
    ResultModule,
    RoundProgressModule,
    ChartsModule,
    ResultModule,
    MatRadioModule,
    CarouselModule.forRoot(),
    ToastrModule.forRoot( {
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    UiSwitchModule,
    TabsModule.forRoot(),
    TabsModule.forRoot(),
    MatSelectModule,
    MatSliderModule,
    MatOptionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    DragDropModule,
    ProgressbarModule.forRoot(),
    NgxUiLoaderModule,
    NgxSpinnerModule,
    InputsModule,
    MatTabsModule,
    NgCircleProgressModule.forRoot(),
    BsDropdownModule.forRoot(),
    DropDownsModule,
    GooglePlaceModule,


  ],
  entryComponents: [
    DialogContactComponent,
    Quotev2Component
  ],
  providers: [
    CookieService,
    BsModalRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    }
  ]
})
export class DeModule {
}
