import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {TermsAndConditionComponent} from './terms-and-condition/terms-and-condition.component';
// import { MetaGuard } from '@ngx-meta/core';
import {AddressComponent} from './address/address.component';
import {NewResultComponent} from './new-result/new-result.component';
import {Homev2Component} from './homev2/homev2.component';
import {Resultv2Component} from './resultv2/resultv2.component';
import {ServiceErrorComponent} from './service-error/service-error.component';
import {DeComponent} from './de/de.component';
import { Homev3Component } from './v3/homev3/homev3.component';
const routes: Routes = [
  {
    path: '',
    component: DeComponent,
    children: [
      {
      path: '',
      component : AddressComponent,
      pathMatch : 'full'
    },
      {
        path: 'de/home',
        component : HomeComponent,
        pathMatch : 'full'
      },

      {
        path: 'privacy-policy',
        component : PrivacyPolicyComponent,
        pathMatch : 'full'
      },
      {
        path: 'de/v1',
        component : AddressComponent,
        pathMatch : 'full'
      },
      {
        path: 'de/V1',
        component : AddressComponent,
        pathMatch : 'full'
      },
      {
        path: 'de/v2',
        component : Homev2Component,
        pathMatch : 'full'
      },
      {
        path: 'de/V2',
        component : Homev2Component,
        pathMatch : 'full'
      },
      {
        path: 'de/v3',
        component : Homev3Component,
        pathMatch : 'full'
      },
      {
        path: 'de/V3',
        component : Homev3Component,
        pathMatch : 'full'
      },
      {
        path: 'de/terms-and-condition',
        component : TermsAndConditionComponent,
        data: {
          meta: {
            title: 'Sweet home',
            description: 'Home, home sweet home... and what?'
          }
        },
        pathMatch : 'full'
      },
      // {
      //   path: 'result',
      //   canActivate: [],
      //   loadChildren: 'src/app/result/result.module#ResultModule',
      //   pathMatch: 'full'
      // },
      {
        path: 'de/result',
        component: NewResultComponent,
      },
      {
        path: 'de/resultV2',
        component: Resultv2Component,
      },
      {
        path: 'de/service-error',
        component: ServiceErrorComponent,
      }
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeRoutingModule { }
