import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {Constants} from '../app.constant';
import {ToastrService} from 'ngx-toastr';
import {HomeService} from '../home/home.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-locationv3',
  templateUrl: './locationv3.component.html',
  styleUrls: ['./locationv3.component.scss']
})
export class Locationv3Component implements OnInit {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSubmit1: EventEmitter<any> = new EventEmitter<any>();
  @Output() mapDetails: EventEmitter<any> = new EventEmitter<any>();
  // @Input() rfunction;
  lang;
  lat;
  data;
  place = false;
  searchLocation;
  accessToken;
  location1;
  convertLang;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  options = {
    type: [],
    componentRestrictions: {country: 'BR'}
  };

  constructor(private appConstants: Constants,
              private toastr: ToastrService,
              private homeService: HomeService,
              private sanitizer: DomSanitizer,
              private route: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private locations: Location) {
    this.appConstants.accessToken.subscribe(value => {
      this.accessToken = value;
    });
    this.convertLang = this.route.snapshot.queryParamMap.get('lang');
    localStorage.setItem('lang', this.route.snapshot.queryParamMap.get('lang'));
  }

  ngOnInit(): void {
    this.checkOrigin();
    this.convertLanguage();
  }
  checkOrigin() {
    let origin;
    const data = {
      origin: ''
    };
    if (this.route.snapshot.queryParams?.origin) {
      data.origin = this.route.snapshot.queryParams?.origin;
    } else if (this.locations['_platformLocation'].location.origin.includes('solextron')) {
      data.origin = 'https://solcalc.solextron.com';
    } else {
      data.origin = 'https://solcalc.solextron.com';
    }
    localStorage.setItem('origin', data.origin);

    this.homeService.checkOrigin(data).subscribe(res => {
      this.appConstants.setToken(res['access_token']);
      this.appConstants.setEmail(res['leadusers']);
    }, error => {
      if (error && error && error.message) {
        this.router.navigate(['br/service-error']);
      }
    });
  }
  convertLanguage() {
    if (this.convertLang === null || this.convertLang === '') {
      localStorage.setItem('lang', 'pt');
      this.translate.use(this.homeService.getLanguage());
    } else {
      this.translate.use(this.convertLang);
    }
  }
  checkSearchedLocation(e) {
    console.log(e, ( /^\s*$/.test(e)));
    if (  /^\s*$/.test(e) === true) {
      return false;
    } else {
      return true;
    }
  }

  public AddressChange(address: Address) {
    this.place = false;
    this.lang = address.geometry.location.lng();
    localStorage.setItem('latitude', this.lang);
    this.lat = address.geometry.location.lat();
    localStorage.setItem('longitude', this.lat);
    this.location1 = address.formatted_address;
    this.searchLocation = address.formatted_address;
    this.appConstants.setReload(false);
    localStorage.setItem('address', this.searchLocation);
    this.appConstants.setLocation(this.searchLocation);
    // console.log(this.lat, this.lang, this.location1, 'address data');
  }

  location() {
    console.log(this.searchLocation, this.checkSearchedLocation(event));
    if (this.lang === '' || this.lat === '' || this.lang === undefined || this.lat === undefined) {
      this.toastr.warning('Please select an address first');
      return false;
    }
    if (this.searchLocation === '' || this.searchLocation === undefined ||  /^\s*$/.test(this.searchLocation) || this.checkSearchedLocation(event) === false) {
      this.toastr.warning('Please type your address ');
      return false;
    } else {
      return true;
    }
  }


  public submitForm(): void {
    if (this.location()) {
      this.onSubmit.emit({
        value: 'Location'
      });
      this.mapDetails.emit({
        lat: this.lat,
        long: this.lang,
        // location: this.location1
      });
    }
  }
}
