import { Component, OnInit } from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {HomeService} from '../../home/home.service';
import {ToastrService} from 'ngx-toastr';
import {Quotev2Component} from '../../quotev2/quotev2.component';

@Component({
  selector: 'app-furtherv2',
  templateUrl: './furtherv2.component.html',
  styleUrls: ['./furtherv2.component.scss']
})
export class Furtherv2Component implements OnInit {

  constructor(private modalService: BsModalService,
              private bsModalRef: BsModalRef,
              private homeService: HomeService,
              private  toastr: ToastrService ) { }

  ngOnInit(): void {
  }
  createQuotation() {
    const initialState = {
      title: 'Contact form',
      class: 'modal-xl'
    };
    this.bsModalRef = this.modalService.show(Quotev2Component, {initialState});


    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose = (myData) => {
      // myData.data.customercontact.lead_id = this.model.leadId;
      this.homeService.updateContactInformations(myData.data).subscribe(reply => {
        const response = reply as any;
        this.bsModalRef.hide();
        this.toastr.success('Quotation upload successfully');
      }, error => {
        this.toastr.warning('Contact Information not updated Please try again', 'Oops');
      });
    };
  }
}
