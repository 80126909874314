<div>
  <div class="d-flex head">
    <h4 class="step-heading">{{'Air-Condition.air-conditioning' | translate}}</h4>
    <p class="float-right" style="color: gray; font-family: 'poppins';">{{'step' | translate }} 6/7</p>
  </div>
  <p>{{'Air-Condition.content' | translate}}</p>
  <div class="spacer-1"></div>
  <div class="form-container">
    <div class="row form-group d-flex flex-column ">
      <div class="row">
        <label class="col-12 col-form-label">
          {{'Air-Condition.no-of-floor' | translate}}
        </label>
      </div>
      <div class="row">
        <div class="col-12 col-form-label">
          <input type="number" class="form-control"
                 placeholder="{{'Air-Condition.no-of-floor' | translate}}" [(ngModel)]="airCondition.noOfFloor" (mousewheel)="$event.preventDefault()">
        </div>
      </div>
    </div>
    <div class="row form-group d-flex flex-column ">
      <div class="row">
        <label class="col-12 col-form-label">
          {{'Air-Condition.no-of-air-condition' | translate}}
        </label>
      </div>
      <div class="row">
        <div class="col-12 col-form-label">
          <input type="number" class="form-control"
                 placeholder="{{'Air-Condition.no-of-air-condition' | translate}}"
                 [(ngModel)]="airCondition.noOfAirCondition" (ngModelChange)="noOfAirCondition()" (mousewheel)="$event.preventDefault()">
        </div>
      </div>
    </div>

    <div class="row form-group d-flex flex-column ">
    </div>


    <div class="row">
      <div class="col-12  text-center" *ngIf="airCondition.noOfAirCondition != null && airCondition.noOfAirCondition >= 0 && airCondition.noOfFloor >= 1">
        <button class="btn btn-primary w-100" (click)="submitForm()">{{'Continue' | translate}}</button>
      </div>

    </div>
  </div>
</div>
