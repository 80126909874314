<div class="container howFuther mt-4  ">
  <!--<div class="row mt-5 " >-->
  <!--<div class="col-12">-->
  <!--<p class="title">{{'how-futher.content' | translate }}!</p>-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="row mt-3" >-->
  <!--<div class="col-12">-->
  <!--<p class="content">{{'how-futher.content1' | translate }}</p>-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="row mt-4 mb-5" >-->
  <!--<div class="col-lg-4 col-xl-4 col-sm-10 col-md-7">-->
  <!--<button type="button" class="btn btn-primary button text-uppercase w-100" (click)=" createQuotation()">{{'contact-form.label' |-->
  <!--translate }}-->
  <!--</button>-->
  <!--</div>-->
  <!--</div>-->
  <!--<img src="./assets/howfuther.png" alt="Snow" style="width:100%;">-->
  <!--<div class="top-left">-->
  <div class="row mt-5 ">
    <div class="col-12">
      <p class="title">{{'how-futher.content' | translate }}!</p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <p class="content">{{'how-futher.content1' | translate }}</p>
    </div>
  </div>
  <div class="row mt-4 mb-5 remove-padding">
    <div class="col-10">
      <button type="button" class="btn btn-primary button text-uppercase" (click)=" createQuotation()">
        {{'contact-form.label' |
        translate }}
      </button>
    </div>
  </div>
  <!--</div>-->

</div>
