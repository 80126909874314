import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-roofpitchv3',
  templateUrl: './roofpitchv3.component.html',
  styleUrls: ['./roofpitchv3.component.scss']
})
export class Roofpitchv3Component implements OnInit {
  @Input() roofPitch;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedPitch: EventEmitter<any> = new EventEmitter<any>();
  constructor(private  toastr: ToastrService) { }

  ngOnInit(): void {
  }
  selectPitch(value): void {
    if(this.roofPitch == value){
      this.roofPitch = undefined
    }else{
    this.roofPitch = value;
    }
  }

  pitchCheck() {
    if (this.roofPitch > 90 || this.roofPitch < 0) {
      this.toastr.warning('Pitch can not be greater than 90 and less than 0');
      if (this.roofPitch < 0) {
        this.roofPitch = 0;
        return false;
      }
      if (this.roofPitch > 90) {
        this.roofPitch = 90;
        return false;
      }
      return false;
    }
  }
  submitForm() {
    if(this.roofPitch || this.roofPitch === 0){
    this.onSubmit.emit({
      value: 'Roof Pitch'
    });
    this.selectedPitch.emit({
      pitch: this.roofPitch
    });
  }else{
      this.toastr.warning('Please select an option first!')
    }
  }
}
