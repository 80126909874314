<div class="modal-header">
  <h4 class="modal-title">Request a Quote</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<!-- <div class="container"> -->
<div class="modal-body px-4">
<!--  <section>-->
    <div class="row ">
      <div class="col-md-4 mt-2">
        <input type="search" id="name" class="form-control" placeholder="{{ 'contact-form.first-name' | translate}}*" [(ngModel)] = "customercontact.customerinfo.firstname"
               aria-label="Search"/>
      </div>
      <div class="col-md-4 mt-2">
        <input type="search" id="lastname" class="form-control" placeholder="{{ 'contact-form.last-name' | translate}}*" [(ngModel)] = "customercontact.customerinfo.lastname"
               aria-label="Search"/>
      </div>
      <div class="col-md-4 mt-2">
        <input type="search" id="selectcountry" class="form-control" (keypress)="numberOnly($event)" [(ngModel)] = "customercontact.customerinfo.phonenumbers"
              placeholder="{{ 'contact-form.phone' | translate}}*" aria-label="Search"/>
      </div>
    </div>
    <div class="row ">
      <div class="col-md-4 mt-2">
        <input type="search" id="email" class="form-control" placeholder="{{ 'contact-form.email' | translate}}*" [(ngModel)] = "customercontact.customerinfo.emailid"
               aria-label="Search"/>
      </div>
      <div class="col-md-4 mt-2">
        <input type="search" id="company" class="form-control" placeholder="{{ 'contact-form.company-name' | translate}}" [(ngModel)] = "customercontact.customerinfo.companyname"
               aria-label="Search"/>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-4 mt-1">
        <input type="search" id="address1" class="form-control" placeholder="{{ 'contact-form.street-address' | translate}}" [(ngModel)] = "customercontact.customerinfo.projectaddress"
               aria-label="Search"/>
      </div>
      <div class="col-md-4 mt-1">
        <input type="search" id="zip" class="form-control" placeholder="{{ 'contact-form.zip-code' | translate}}" (keypress)="numberOnly($event)" [(ngModel)] = "customercontact.customerinfo.zip"
               aria-label="Search"/>
      </div>
      <div class="col-md-4 mt-1">
        <div class="row">
          <div class="col-6">

          <input type="search" id="country" class="form-control pr-1" placeholder="{{ 'contact-form.country' | translate}}*" [(ngModel)] = "customercontact.customerinfo.country"
                 aria-label="Search"/>
          </div>
          <div class="col-6">
            <input type="search" id="State" class="form-control" placeholder="{{ 'contact-form.city' | translate}}" [(ngModel)] = "customercontact.customerinfo.city"
                   aria-label="Search"/>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-3">
        <span>{{ 'contact-form.interest' | translate}}</span>
      </div>
      <div class="col-md-3">
        <div class="both-side-toggle-wrapper">
          <ui-switch size="small" [(ngModel)] = "customercontact.customerinfo.heatpump" ></ui-switch>
          <label>{{ 'contact-form.heat-pump' | translate}}</label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="both-side-toggle-wrapper">
          <ui-switch size="small" [(ngModel)] = "customercontact.customerinfo.station"></ui-switch>
          <label>{{ 'contact-form.charge-station1' | translate}}</label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="both-side-toggle-wrapper">
          <ui-switch size="small" [(ngModel)] = "customercontact.customerinfo.energymanagment"></ui-switch>
          <label>{{ 'contact-form.energy1' | translate}}</label>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <textarea class="form-control w-100" rows="5" placeholder="{{ 'contact-form.message' | translate}}" id="comment" [(ngModel)] = "customercontact.customerinfo.message" ></textarea>
      </div>
    </div>
<!-- </section>info-->
<!--  <section>-->
    <div class="row mt-4 ">
      <div class="col d-flex justify-content-center ">
        <div class="both-side-toggle-wrapper">
          <ui-switch size="small" [(ngModel)] = "customercontact.customerinfo.allow"></ui-switch>
          <label>{{ 'contact-form.allow' | translate}}</label>
        </div>
      </div>
    </div>
    <div class="row my-3 ">
      <div class="col d-flex justify-content-center">
        <button type="button" style="font-size: 17px" class="btn btn-secondary" (click)="confirm()">{{ 'contact-form.submit' | translate}}</button>
      </div>
    </div>
<!--  </section>-->

<!-- </div> -->
