<section class="m-0 p-0 overflow-hidden" >

<!--  <nav class="row navbar">-->
<!--    <a class="navbar-brand" ><span class="navhead">Solar Calculator</span></a>-->
<!--  </nav>-->
    <div class="row k-justify-content-evenly text-center align-content-end headernav p-3" >
  <div *ngFor="let heading of headArr">
    <a class="col-1 headtag" (click)="stepChange(heading.title)" [ngClass]="{'active' : activeStep === heading.title , 'dis': activeStep!== heading.title}">{{translateLang(heading.title)}}</a>
  </div>
  </div>

  <div class="row" style="max-height: 650px; overflow-y: auto;">
    <div class="col-12" >
      <div *ngIf="activeStep == 'Independence'" >
        <app-independencev3 ></app-independencev3>
      </div>
      <div *ngIf="activeStep == 'Consumption'">
        <app-result-consumptionv3></app-result-consumptionv3>
      </div>
      <div *ngIf="activeStep == 'Financial'">
        <app-financialv3></app-financialv3>
      </div>
      <div *ngIf="activeStep == 'A Typical Day'">
        <app-typical-dayv3></app-typical-dayv3>
      </div>
      <div *ngIf="activeStep == 'Sustainability'">
        <app-sustainabilityv3></app-sustainabilityv3>
      </div>
      <div *ngIf="activeStep == 'How Further'">
        <app-furtherv3></app-furtherv3>
      </div>
      <div class="mb-1">
        <img style="width: 100%" src="assets/bgimg.png" >
      </div>
    </div>
  </div>

</section>
