<section>
  <div class="container justify-content-center text-center">
    <div class="row mt-5 p-3">
      <div class="col "><span class="dtext">APPLIANCES</span></div>
    </div>
    <div class="row pb-3 heading ">
      <div class="col "><span class="htext">
      What is your motivation to use solar?</span>
      </div>
    </div>
    <div class="row">
      <div class="col">
<!--        '<mat-tab-group>-->
<!--          <mat-tab>-->
<!--            <div class="spacer-1"></div>-->
<!--            <ul class="list-group">-->
<!--              <li class="list-group-item float-right ml-2"-->
<!--                  style="cursor: pointer;color:black" title="Select Priority">-->
<!--                <label class="radio-but">-->
<!--                  <input type="radio" class="radio-but__input">-->
<!--                  <span class="radio-but__control"></span>-->
<!--                  <span class="radio-but__label"></span>-->
<!--                </label>-->
<!--              </li>-->

<!--            </ul>-->
<!--          </mat-tab>-->
<!--        </mat-tab-group>'-->
      </div>
    </div>



  </div>
</section>
