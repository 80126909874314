import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {DomSanitizer} from "@angular/platform-browser";
import {HouseRoofTypeModel} from "./HouseRoofType.model";

@Component({
  selector: 'app-housetypev2',
  templateUrl: './housetypev2.component.html',
  styleUrls: ['./housetypev2.component.scss']
})
export class Housetypev2Component implements OnInit {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedData: EventEmitter<any> = new EventEmitter<any>();
  shapes: HouseRoofTypeModel[];
  selectedShape;
  public  twoSideOccupy = false;
  constructor(private sanitizer: DomSanitizer,
              private toastr: ToastrService
  ) {
    this.shapes = [
      {
        key: 'gable',
        showToggle: true,
        seq: 'first' ,
        iconSvg: this.sanitizer.bypassSecurityTrustHtml('<svg id="gable" xmlns="http://www.w3.org/2000/svg" width="100" height="78.35" viewBox="0 0 78.496 65.017"><defs><style>.a{fill:#677171;opacity:0.4;}</style></defs><path class="a" d="M313.928,131.576l4.061,2.344v17.211a1.077,1.077,0,0,0,.538.933l22.383,12.923a1.072,1.072,0,0,0,1.077,0l44.767-25.846a1.077,1.077,0,0,0,.538-.933v-11.9l4.061-2.344a1.077,1.077,0,0,0,.334-1.562L375.9,100.559a1.091,1.091,0,0,0-1.113-.418c-16.862,3.853-15.878,3.6-16.089,3.726l-44.768,25.846a1.081,1.081,0,0,0,0,1.863Zm28.6,30.614v-14.1l2.984,1.723a1.093,1.093,0,0,0,1.077,0l38.554-22.259v10.035Zm3.826-14.731-14.522-20.088,42.892-24.764,14.522,20.088Z" transform="translate(-313.395 -100.115)"/></svg>')
      },
      {

        key: 'pyramid',
        showToggle: true,
        seq: 'second' ,
        iconSvg: this.sanitizer.bypassSecurityTrustHtml('<svg id="pyramid" xmlns="http://www.w3.org/2000/svg" width="100" height="78.35" viewBox="0 0 78.496 61.426"><defs><style>.a{fill:#677171;opacity:0.4;}</style></defs><path class="a" d="M391.344,788.346l-31.579-18.233a1.077,1.077,0,0,0-1.077,0c-47.959,27.69-44.881,25.852-45.094,26.146a1.151,1.151,0,0,0-.206.569,1.077,1.077,0,0,0,.122.558c.194.373.108.258,4.475,2.78v17.21a1.077,1.077,0,0,0,.538.933l22.382,12.922a1.066,1.066,0,0,0,.538.144c.46,0-2.6,1.669,45.3-25.989a1.077,1.077,0,0,0,.538-.933v-11.9l4.06-2.344a1.077,1.077,0,0,0,0-1.865Zm-7.373-1.771-29.533-6.9,5.1-7.206Zm-32.773-4.42-5.93,31.276-28.412-16.4Zm-8.676,46.278v-14.1a33,33,0,0,0,3.22,1.82,1,1,0,0,0,.556.013c.244-.06-1.806,1.1,38.834-22.368v10.035Zm5.018-15.418,5.945-31.352,34.426,8.043Z" transform="translate(-313.387 -769.968)"/></svg>')
      },
      {
        key: 'hip',
        showToggle: true,
        seq: 'third' ,
        iconSvg: this.sanitizer.bypassSecurityTrustHtml('<svg id="hip" xmlns="http://www.w3.org/2000/svg" width="100" height="78.35" viewBox="0 0 78.496 61.43"><defs><style>.a{fill:#677171;opacity:0.4}</style></defs><path class="a" d="M535.21,134.322c.206.3-.016.122,4.4,2.67V154.2a1.077,1.077,0,0,0,.538.933l22.384,12.923a1.077,1.077,0,0,0,1.077,0l44.766-25.846a1.077,1.077,0,0,0,.538-.933v-11.9l4.061-2.344a1.077,1.077,0,0,0,.148-1.763c-23.542-19.431-22.177-18.427-22.692-18.458-.177-.011-9.922-.022-9.576-.022-.449,0,2.622-1.681-45.305,25.99a1.069,1.069,0,0,0-.338,1.539Zm22.979-6.887,7.538,22.149L538.7,133.977Zm5.957,23.724c3.159,1.824,3.149,1.867,3.522,1.867.431,0-2.075,1.364,39.092-22.4v10.03l-42.612,24.6Zm4.119-.8-8.1-23.8,30.087-17.371,20.3,16.756Z" transform="translate(-535.016 -106.773)"/></svg>')
      },
      {

        key: 'shed',
        showToggle: true,
        seq: 'fourth' ,
        iconSvg: this.sanitizer.bypassSecurityTrustHtml('<svg id="shed" xmlns="http://www.w3.org/2000/svg" width="100" height="78.35" viewBox="0 0 78.496 68.735"><defs><style>.a{fill:#677171;opacity:0.4;}</style></defs><path class="a" d="M366.761,536.278a1.089,1.089,0,0,0-1.294-.154l-45.274,26.139a1.089,1.089,0,0,0-.2,1.733l3.415,3.237v23.321a1.089,1.089,0,0,0,.545.943l22.637,13.069a1.089,1.089,0,0,0,1.089,0l45.274-26.139a1.089,1.089,0,0,0,.545-.943v-7.678l4.107-2.371a1.089,1.089,0,0,0,.2-1.734Zm-18.542,65.458V590.754l2.813,2.668a1.089,1.089,0,0,0,1.294.154l38.99-22.51v5.789Zm3.722-10.456-29.394-27.86,43.306-25,29.394,27.86Z" transform="translate(-319.649 -535.979)"/></svg>')
      },
      {
        key: 'flat',
        showToggle: true,
        seq: 'fifth' ,
        iconSvg: this.sanitizer.bypassSecurityTrustHtml('<svg id="flat" xmlns="http://www.w3.org/2000/svg" width="100" height="78.35" viewBox="0 0 78.496 65.429"><defs><style>.a{fill:#677171;opacity:0.4;}</style></defs><path class="a" d="M759.018,130.806l3.8,2.192v18.566a1.085,1.085,0,0,0,.542.939l22.549,13.019a1.085,1.085,0,0,0,1.085,0l45.1-26.037a1.085,1.085,0,0,0,.542-.939V119.98l3.8-2.192a1.085,1.085,0,0,0,0-1.879l-26.888-15.524a1.085,1.085,0,0,0-1.085,0l-49.437,28.543a1.085,1.085,0,0,0,0,1.879ZM809,102.577l24.719,14.271-47.268,27.29-24.719-14.271Zm-21.464,43.44,42.928-24.784v16.685L787.534,162.7Z" transform="translate(-758.476 -100.239)"/></svg>')
      },
    ]
  }

  ngOnInit(): void {}
  public getIconClass(shape) {
    // console.log('Called', shape.key, this.selectedShape)
    if (this.selectedShape && this.selectedShape.key === shape.key) {
      return shape.seq + ' selected';
    }
    return shape.seq;
  }
  public selectShape(shape, i): void {

    if (shape == this.selectedShape ) {
      this.selectedShape = '' ;
    } else {
      this.selectedShape = shape;
    }
    // console.log(this.selectedShape);
  }
  submitForm() {
    // console.log(this.selectedShape)
    if(this.selectedShape) {
      this.selectedData.emit({
        value: this.selectedShape, twoSideOccupy: this.twoSideOccupy
      });
      this.onSubmit.emit({
        value: 'House Type'
      });
    } else {
      this.toastr.warning('Please select an option first!');
    }
  }
}
