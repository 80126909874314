<section>
  <div class="container justify-content-center text-center">
    <div class="row mt-5 p-3">
      <div class="col "><span class="dtext">{{'independence.label1' | translate }}</span></div>
    </div>
    <div class="row pb-3 heading ">
      <div class="col p-3"><span class="htext">
      {{'independence.content' | translate }}</span>
      </div>
    </div>
    <!--switch toggler-->
    <div class="row justify-content-center">
      <div class="col-md-6 d-flex justify-content-md-end ">
        <div class="form-check">
          <input class="form-check-input input" value="PV-without-storage" type="radio"  [(ngModel)]="storage" (change)="changeSeason()" name="flexRadioDefault" id="flexRadioDefault1">
          <label class="form-check-label label" for="flexRadioDefault1">
            {{'pv-without-storage' | translate }}
          </label>
        </div>
        <span><i class="fas fa-info-circle" tooltip="Photovoltaic system without battery"> </i></span>
      </div>
      <div class="col-md-6 d-flex justify-content-start">
        <div class="form-check">
          <input class="form-check-input input" value="PV-with-storage" type="radio" [(ngModel)]="storage" (change)="changeSeason()" name="flexRadioDefault" id="flexRadioDefault2">
          <label class="form-check-label label" for="flexRadioDefault2">
            {{'PV-with-storage' | translate }}
          </label>
        </div>
        <span><i class="fas fa-info-circle" tooltip="Photovoltaic system with battery"> </i></span>

      </div>
    </div>

    <!--cards-->
    <div class="row mt-5">

        <div class="graph col-sm-12 col-md mt-2 mb-2 p-2" >
          <div class="card pt-3">
          <circle-progress
            [percent]="yearPercent"
            [radius]="radius"
            [outerStrokeColor]="'#154A75'"
            [innerStrokeColor]="'#D9DDDF'"
            [titleFontWeight]="90"
            [titleColor]= "'#154A75'"
            [unitsColor]="'#154A75'"
            [titleFontSize]=""
            [outerStrokeWidth]="10"
            [innerStrokeWidth]="10"
            [space]="-10"
            [animation]="true"
            [animationDuration]="300"
            [showSubtitle]="false"
          ></circle-progress>
          <p style="color: #154A75">{{'season.whole-year' | translate}}</p>
        </div>
        </div>

      <div class="graph col-sm-12 col-md mt-2 mb-2 p-2" >
        <div class="card pt-3">
          <circle-progress
            [percent]="autumnPercent"
            [radius]="radius"
            [outerStrokeColor]="'#2F9E6A'"
            [innerStrokeColor]="'#D9DDDF'"
            [titleFontWeight]="90"
            [titleColor]= "'#2F9E6A'"
            [unitsColor]="'#2F9E6A'"
            [titleFontSize]=""
            [outerStrokeWidth]="10"
            [innerStrokeWidth]="10"
            [space]="-10"
            [animation]="true"
            [animationDuration]="300"
            [showSubtitle]="false"
          ></circle-progress>
          <p style="color: #2F9E6A">{{'season.spring' | translate}}</p>
        </div>
      </div>

      <div class="graph col-sm-12 col-md mt-2 mb-2 p-2" >
        <div class="card pt-3">
          <circle-progress
            [percent]="winterPercent"
            [radius]="radius"
            [outerStrokeColor]="'#FFCC00'"
            [innerStrokeColor]="'#D9DDDF'"
            [titleFontWeight]="90"
            [titleColor]= "'#FFCC00'"
            [unitsColor]="'#FFCC00'"
            [titleFontSize]=""
            [outerStrokeWidth]="10"
            [innerStrokeWidth]="10"
            [space]="-10"
            [animation]="true"
            [animationDuration]="300"
            [showSubtitle]="false"
          ></circle-progress>
          <p style="color: #FFCC00">{{'season.summer' | translate}}</p>
        </div>
      </div>

      <div class="graph col-sm-12 col-md mt-2 mb-2 p-2" >
        <div class="card pt-3">
          <circle-progress
            [percent]="springPercent"
            [radius]="radius"
            [outerStrokeColor]="'#B16848'"
            [innerStrokeColor]="'#D9DDDF'"
            [titleFontWeight]="90"
            [titleColor]= "'#B16848'"
            [unitsColor]="'#B16848'"
            [titleFontSize]=""
            [outerStrokeWidth]="10"
            [innerStrokeWidth]="10"
            [space]="-10"
            [animation]="true"
            [animationDuration]="300"
            [showSubtitle]="false"
          ></circle-progress>
          <p style="color: #B16848">{{'season.autumn' | translate}}</p>
        </div>
      </div>


      <div class="graph col-sm-12 col-md mt-2 mb-2 p-2" >
        <div class="card pt-3">
          <circle-progress
            [percent]="summerPercent"
            [radius]="radius"
            [outerStrokeColor]="'#64C7DC'"
            [innerStrokeColor]="'#D9DDDF'"
            [titleFontWeight]="90"
            [titleColor]= "'#64C7DC'"
            [unitsColor]="'#64C7DC'"
            [titleFontSize]=""
            [outerStrokeWidth]="10"
            [innerStrokeWidth]="10"
            [space]="-10"
            [animation]="true"
            [animationDuration]="300"
            [showSubtitle]="false"
          ></circle-progress>
          <p style="color: #64C7DC">{{'season.winter' | translate}}</p>
        </div>
      </div>

    </div>
  </div>

</section>

