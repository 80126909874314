import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-home-air-condition',
  templateUrl: './home-air-condition.component.html',
  styleUrls: ['./home-air-condition.component.scss']
})
export class HomeAirConditionComponent implements OnInit {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
 airCondition = {
   noOfAirCondition: 0,
   noOfFloor: 1
 };
  constructor(private  toastr: ToastrService) { }

  ngOnInit(): void {
  }
  public submitForm(): void {
    this.onSubmit.emit({
      value: this.airCondition
    });
  }
  noOfAirCondition() {
    if (this.airCondition.noOfAirCondition < 0) {
      this.toastr.warning('Air Condition can not be less than 0');
      // this.airCondition.noOfAirCondition = 0;
      return false;
    }
  }

}
