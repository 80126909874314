import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders
} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/catch';
import {ApiEndPoints, Constants} from '../app.constant';
import {AuthService} from './auth.service';
import {any} from "codelyzer/util/function";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  accessToken;
  accessTokenPreProd;

  constructor(private appConstants: Constants, private authService: AuthService, private apiEndPoints: ApiEndPoints) {
    this.appConstants.accessToken.subscribe(value => {
      this.accessToken = value as string;
    });
    this.appConstants.accessTokenPreProd.subscribe(value => {
      this.accessTokenPreProd = value as string;
      // console.log(this.accessTokenPreProd);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // tslint:disable-next-line:max-line-length
    if (!request.url.includes(this.apiEndPoints.AUTH_GOOGLE_CLOUD) && !request.url.includes('https://storage.googleapis.com/upload/storage/v1/b/solbucket/o') && !request.url.includes('https://maps.googleapis.com/maps/api/geocode/json')) {
      let modifiedRequest;
      console.log(this.accessToken);
      modifiedRequest = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.accessToken
        }
      });
      return next.handle(modifiedRequest);
    }
    // if (request.url.includes(this.apiEndPoints.LOGIN_REQUEST_PRE_PROD)) {
    //   console.log('inter')
    //   const header = request.clone({
    //     setHeaders: {
    //       'Content-Type': 'application/json',
    //     }
    //   });
    //   return next.handle(header);
    // }
    if (request.url.includes('https://maps.googleapis.com/maps/api/geocode/json')) {
      console.log('second');
      const header = request.clone({
        setHeaders: {
        }
      });
      return next.handle(header);
    }
    if (request.url.includes(this.apiEndPoints.AUTH_GOOGLE_CLOUD)) {
      console.log('third');
      const header = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('imageAuth')
        }
      });
      return next.handle(header);
    }
    if (request.url.includes('https://storage.googleapis.com/upload/storage/v1/b/solbucket/o')) {
      console.log('fourth');
      const header = request.clone({
        setHeaders: {
        'Content-Type': 'image/png',
        Authorization: 'Bearer ' + localStorage.getItem('imageAccessToken')
      }
    })
      ;
      return next.handle(header);
    }
  }

}
