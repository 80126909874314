<div class="container-fluid wrapper-address">
  <div class="row wrapper-row justify-content-center d-flex  align-items-center">
    <div class="col-12">
      <div class="address-page " >
        <div class="title mb-4 text-center">You are not allowed to use this service</div>
        <div class="content mt-4 text-center mb-4 px-5">
<!--          <p>{{'address.content' | translate}}</p>-->
        </div>
      </div>
    </div>
  </div>
</div>
