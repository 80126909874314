<section class="m-0 p-0 overflow-hidden background" style="height: 100vh; display: flex; align-items: stretch; flex-direction: column" >

  <!--  <nav class="row navbar">-->
  <!--    <a class="navbar-brand" ><span class="navhead">Solar Calculator</span></a>-->
  <!--  </nav>-->
  <div class="row k-justify-content-evenly text-center align-content-end headernav p-3" >
    <div *ngFor="let heading of headArr">
      <a class="col-1 headtag" (click)="stepChange(heading.title)" [ngClass]="{'active' : activeStep === heading.title , 'dis': activeStep!== heading.title}">{{translateLang(heading.title)}}</a>
    </div>
  </div>

  <div class="row" style="flex: 1; overflow-y: auto">
    <div class="col-12" style="height: 100%" >

      <ng-container *ngIf="activeStep == 'Independence'" >
        <app-independencev2></app-independencev2>
      </ng-container>

      <ng-container *ngIf="activeStep == 'Consumption'">
        <app-result-consumptionv2></app-result-consumptionv2>
      </ng-container>

      <ng-container *ngIf="activeStep == 'Financial'">
        <app-financialv2></app-financialv2>
      </ng-container>

      <ng-container *ngIf="activeStep == 'A Typical Day'">
        <app-typicaldayv2></app-typicaldayv2>
      </ng-container>

      <ng-container *ngIf="activeStep == 'Sustainability'">
        <app-sustainabilityv2></app-sustainabilityv2>
      </ng-container>

      <ng-container *ngIf="activeStep == 'How Further'">
        <app-furtherv2></app-furtherv2>
      </ng-container>

    </div>
  </div>

</section>
