<div class="container-fluid px-0 h-100">
    <div class="row remove-extra-space wrapper-row ">
      <div class="col ">
        <div class="map-wrapper">
  
          <div id="viewDiv"></div>
  
          <div class="esri-widget sketch" id="sketchPanel">
  
            <div id="search"
                 style="display:inline-block;width: 100%; position: relative; vertical-align: text-bottom "></div>
  
  
            <div style="display: flex; margin-top: 5px">
  
              <button class="button-sketch esri-button sketch button-1" data-type="polygon" id="building"
                      style=" text-transform: uppercase; margin-left: 0px; margin-right: 5px">
                <i class="material-icons sketch" id="createIcon">create</i>
              </button>
              <button class="button-sketch esri-button sketch button-1" id="delete"
                      style=" text-transform: uppercase; margin-left: 0px; margin-right: 5px">
                <i class="material-icons sketch" id="deleteIcon">delete</i>
              </button>
              <button class="button-sketch esri-button sketch button-1" id="info"
                      style=" text-transform: uppercase; margin-left: 0px; margin-right: 5px">
                <i class="material-icons sketch" id="infoIcon">info</i>
              </button>
              <div class="mdc-text-field mdc-text-field--outlined mdc-text-field--no-label params">
                <input class="mdc-text-field__input disinput mdc-text-field--disabled" disabled id="selectedArea"
                       type="text"
                       value="0">
                <div class="mdc-notched-outline">
                  <div class="mdc-notched-outline__leading"></div>
                  <div class="mdc-notched-outline__notch"></div>
                  <div class="mdc-notched-outline__trailing"></div>
                </div>
              </div>
            </div>
          </div>
  
          <div id="infopopup" class="popup">
            <div class="popup-material">
              <i class="material-icons top-right button" id="closepopup">clear</i>
              <table style="width:100%">
                <tr class="tr-info">
                  <td><span>{{'Map.pencilInfo' | translate}}</span>
                  </td>
                  <td><i class="material-icons sketch">create</i></td>
                </tr>
                <tr class="tr-info">
                  <td><span>{{'Map.deleteInfo' | translate}}</span></td>
                  <td><i class="material-icons sketch">delete</i></td>
                </tr>
  
              </table>
            </div>
  
          </div>
  
  
          <div>
            <button type="button" style="font-size: 17px" class="btn btn-secondary my_button"
                    (click)="submitForm()">{{'Continue' | translate}}
            </button>
  
          </div>
  
        </div>
      </div>
    </div>
  </div>
  
  