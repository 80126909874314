import {
  AfterContentChecked,
  ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output,
  SimpleChanges
} from '@angular/core';
import {HomeService} from '../home/home.service';
import {ToastrService} from 'ngx-toastr';
import {HouseConsumption} from "./house-consumption.model";
import {Constants} from '../../app.constant';
import {ResultDataService, ResultService} from '../result/result.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';



@Component({
  selector: 'app-house-consumption',
  templateUrl: './house-consumption.component.html',
  styleUrls: ['./house-consumption.component.scss']
})
export class HouseConsumptionComponent implements OnInit, OnChanges{

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() consumptionStep;
  @Output() skip: EventEmitter<any> = new EventEmitter<any>();
  @Output() extraInput1: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedEprice: EventEmitter<any> = new EventEmitter<any>();
  @Input() public consumption: HouseConsumption;
  @Input() leadId: string;
  @Input() yearly: string;
  data;
  electricId ;
  electrics = [];
  edit = 0;
  getSupplier = false;
  selectedState;
  ePrice;
  nm;
  pvTariff;
  ePriceObj: any;
  nmObj: any;
  pvTariffObj;
  stateList = [];
  email: string;

  constructor(private appConstants: Constants,
              private homeService: HomeService,
              private  toastr: ToastrService,
              private cdr: ChangeDetectorRef,
              private  resultService: ResultService,
              private resultDataService: ResultDataService,
              private router: Router,
              private loader: NgxSpinnerService) {
    this.getSupplier = false;
    this.consumption = new HouseConsumption();
    this.consumption.numPeople = 2;
    this.appConstants.email.subscribe(value => {
      this.email = value as string;
    });
  }

  ngOnInit(): void {
    this.electrics = [];
    this.extraInput(event);
    this.getState();
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log('ng change ---> ', this.yearly)
    if (this.leadId && this.leadId !== '' && this.yearly && this.yearly !== '') {
      this.changeStateTariffDetails();
    }
  }

  changeStateTariffDetails() {
    localStorage.setItem('state', this.selectedState);
    this.ePrice = this.ePriceObj[this.selectedState];
    this.nm = this.nmObj[this.selectedState];
    this.pvTariff = this.pvTariffObj[this.selectedState];
    this.electricId = 0;
    console.log(this.nm, this.pvTariff);
    this.sendEprice(this.ePrice);
  }
  sendEprice(ePrice): void {
    this.selectedEprice.emit({
      ePrice
    });
  }

  public submitForm(): void {
    if (this.consumption.numPeople && this.consumption.monthlyEbill) {
      this.uploadYearlyConsumption();
      this.onSubmit.emit({
        value: this.consumption, electricId: this.electricId
      });
    } else {
      this.toastr.warning('Please enter the details.');
    }
  }

  uploadYearlyConsumption() {
    this.homeService.postStateTariffDetails(this.leadId, 'upload', {
      eprice: this.ePrice,
      state: this.selectedState,
      ebillmonthly: this.consumption.monthlyEbill,
      nm: this.nm,

    }).subscribe( res => {
      const response = res as any;
      console.log(response);
      this.getResult();
    }, error => {
      this.toastr.warning('Yearly Electric not updated Please try again', 'Oops');
    });
  }
  getResult() {
    this.resultService.getResult().subscribe(res => {
      this.data = res as any;
      this.router.navigate(['de/result']);
      if (this.data.status) {
      }
      this.resultDataService.setResult(this.data);
    }, error => {
      this.toastr.warning('Something went wrong try again', 'Oops');
    });
  }

  public skip1() {
    this.skip.emit({
      value: this.consumption
    });
  }

  public extraInput(event): void {
    this.extraInput1.emit({
        value: this.consumption
      });
  }

  getState() {
    this.loader.show()
    this.homeService.getState(this.leadId, 'eprice').subscribe(res => {
      const response = res as any;
      if (res) {
        this.loader.hide()
      }
      for (const state of response.states ) {
        this.stateList.push(state);
      }
      this.ePriceObj = {
        ...response.eprice
      };
      this.nmObj = {
        ...response.nm
      };
      this.pvTariffObj = {
        ...response.pvtariff
      };
      console.log(this.ePriceObj);
      this.stateList.sort();
    }, error => {
      this.toastr.error('State data not found', 'Oops');
    });
  }

  // yearlyEnergyConsumtion() {
  //   if (this.airCondition['noOfAirCondition'] == 0) {
  //     this.consumption.YearlyElecRequired = Math.round((Math.round(567 * this.consumption.numPeople + 2116.8) * 0.6) / 12);
  //   } else {
  //     this.consumption.YearlyElecRequired = Math.round((Math.round(1097 * this.consumption.numPeople + 2614.1) * 0.6) / 12);
  //   }
  // }
  getElectricSupplier() {
    this.electricId = 0;
    this.homeService.getElectricSupplier({
      email: this.email,
      lead_id: this.leadId,
      action: 'getsupplierdata'
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
      this.electrics = this.data.ListofSuppliers;
      // this.electricId = this.data.ListofSuppliers[0].elecID;
      this.uploadElectricId();
      this.electricId  = parseInt(this.data.ListofSuppliers[0].elecID);
    }, error => {
      this.toastr.warning('Supplier list not found Please try again ', 'Oops');
      this.getSupplier = true;
    });
  }

  uploadElectricId() {

    this.homeService.uploadElectricId({
      email: this.appConstants.email,
      lead_id: this.leadId,
      action: 'upload',
      elecID: this.electricId,
    }).subscribe(res => {
      const response = res as any;
      this.data = response;
    });
  }

}
