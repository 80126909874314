import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-hotwaterv2',
  templateUrl: './hotwaterv2.component.html',
  styleUrls: ['./hotwaterv2.component.scss']
})
export class Hotwaterv2Component implements OnInit {

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedData: EventEmitter<any> = new EventEmitter<any>();
  constructor(private  toastr: ToastrService) { }
  selectedHotWaterEquipment;
  ngOnInit(): void {
  }

  selectOption(value) {
    if (this.selectedHotWaterEquipment == value) {
      this.selectedHotWaterEquipment = '';
    } else {
      this.selectedHotWaterEquipment = value;
    }
    console.log(this.selectedHotWaterEquipment);
  }
  submitForm() {
    if (this.selectedHotWaterEquipment) {
      this.onSubmit.emit({
        value: 'Hot Water'
      });
      this.selectedData.emit({
        value: this.selectedHotWaterEquipment
      });
    } else {
      this.toastr.warning('Please select an option first!')
    }
  }
}
