<section class="m-0 p-0 overflow-hidden background"
  style=" display: flex; align-items: stretch; flex-direction: column">

  <!--  <nav class="row navbar">-->
  <!--    <a class="navbar-brand"><span class="navhead">{{'address.solar' | translate}}</span></a>-->
  <!--  </nav>-->
  <div class="container-fluid px-0 position-relative">
    <div class="row justify-content-around text-center align-content-center headernav ">
      <div *ngFor="let head of headArr">
        <!--   (click)="stepChange(head.title)"   -->
        <a class="col-md-1 headtag" (click)="stepChange(head.title)"
          [ngClass]="{'active' : activeStep === head.title , 'dis': activeStep!== head.title, 'condition' : selectedV === head.selected  }">{{translateLang(head.title)}}</a>
        <i class="fas arrowicon fa-chevron-circle-right"
          [ngClass]="{'active' : activeStep === head.title, 'disar': activeStep!== head.title, 'condition' : selectedV === head.selected }"></i>
      </div>
    </div>
    <div class="row" *ngIf="!showLoader">
      <!--    [ngStyle]="{backgroundImage: 'url(assets/bgimg.png)'}"-->
      <div class="col-12">
        <div *ngIf="activeStep === 'Location'">
          <app-locationv3 (onSubmit)="next($event.value)" (mapDetails)="receiveData($event)"></app-locationv3>
        </div>
        <div *ngIf="activeStep === 'Roof Area'">
          <app-roofareav3 (onSubmit)="next($event.value)" (Details)="receiveData($event)"></app-roofareav3>
          <!--        [value]=""-->
        </div>
        <div *ngIf="activeStep === 'House Type'">
          <app-housetypev3 (onSubmit)="next($event.value)" (selectedData)="selectedData($event)"></app-housetypev3>
          <!--        [email]="email"-->
        </div>
        <div *ngIf="activeStep === 'Roof Pitch'">
          <app-roofpitchv3 (onSubmit)="next($event.value)" (selectedPitch)="receivedPitch($event)"></app-roofpitchv3>
        </div>
        <div *ngIf="activeStep === 'Orientation'">
          <app-orientationv3 (onSubmit)="next($event.value)" (angle)="receiveData($event)" [tilt]="roofPitch"
            [roofTypeKey]="roofTypeData" [numSurface]="numSurface"></app-orientationv3>
        </div>
        <div *ngIf="activeStep === 'Hot Water'">
          <app-hotwaterv3 (onSubmit)="next($event.value)"
            (selectedData)="selectedDataHotWater($event)"></app-hotwaterv3>
        </div>
        <div *ngIf="activeStep === 'Heater'">
          <app-heaterv3 (onSubmit)="next($event.value)" (selectedData)="selectedHeater($event)"></app-heaterv3>
        </div>
        <div *ngIf="activeStep === 'Average Consumption'">
          <app-consumptionv3 (onSubmit)="next($event.value)" (extraInput1)="uploadExtraInput1($event)"
            (selectedEprice)="getEprice($event)"></app-consumptionv3>
        </div>

      </div>
    </div>
  </div>
</section>

<div class="result-bar d-flex align-items-center" style="height:100% ;width: 100%;  margin-top: 200px"
  *ngIf="showLoader">
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12  ">
        <p class="fetch-result text-center" style="color: #3E494A; font-size: 35px; font-weight: bold">
          {{'Preparing-Results' | translate}}</p>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <div class="progress" style="height: 37px">
          <div class="progress-bar progress-bar-striped bg-success progress-bar-animated" role="progressbar"
            style="width: 100%; background-color: #154A75 !important;" aria-valuenow="0" aria-valuemin="0"
            aria-valuemax="100"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="text-center result-content" style="color: #677171; font-size: 17px">
          {{'content' | translate}}
          {{'content1' | translate}}
          {{'content2' | translate}}
          {{'content3' | translate}}
        </p>
      </div>
    </div>
  </div>
</div>