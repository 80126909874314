<section class="container-fluid" style="padding-bottom: 100px" >
  <div class=" justify-content-center text-center">
    <div class="row mt-5 p-3">
      <div class="col "><span class="dtext">{{'typical-day.label1' | translate }}</span></div>
    </div>
  </div>

  <!--switch toggler-->
  <div class="row mt-3">
    <div class="col-md-6 d-flex justify-content-md-end justify-content-sm-start">
      <div class="form-check">
        <input class="form-check-input input" [(ngModel)]="storage" (change)="changeSeason()" value="PV-without-storage" type="radio" name="flexRadioDefault"
               id="flexRadioDefault1">
        <label class="form-check-label label1" for="flexRadioDefault1">
          {{'pv-without-storage' | translate }}
        </label>
      </div>
      <span><i class="fas fa-info-circle" tooltip="Photovoltaic system without battery"> </i></span>
    </div>

    <div class="col-md-6 d-flex justify-content-start  ">
      <div class="form-check">
        <input class="form-check-input input" [(ngModel)]="storage" (change)="changeSeason()" value="PV-with-storage" type="radio" name="flexRadioDefault"
               id="flexRadioDefault2">
        <label class="form-check-label label1" for="flexRadioDefault2">
          {{'PV-with-storage' | translate }}
        </label>
      </div>
      <span><i class="fas fa-info-circle" tooltip="Photovoltaic system with battery"> </i></span>
    </div>

  </div>

  <!--  second row of switches-->
  <div class="row my-5 toggle">
    <div class=" col-md-6 mt-4 first secondrow d-flex justify-content-end">


      <ui-switch  size="small" color="yellow" [(ngModel)]="showProduction" (change)="toggleDataSeries('production')"></ui-switch>
      <label class="slabel">{{'typical-day.production' | translate }}[kWh]</label>

      <ui-switch size="small" [(ngModel)]="showConsumption"
                 (change)="toggleDataSeries('consumption')" color="green"></ui-switch>
      <label class="slabel">{{'typical-day.consumption' | translate }}[kWh]</label>


    </div>
    <div class=" col-md-6 mt-4 secondrow d-flex justify-content-start">
      <ui-switch size="small" [disabled]="disableBattery" [(ngModel)]="showBattery"
                 (change)="toggleDataSeries('battery')" color="blue"></ui-switch>
      <label class="slabel">{{'typical-day.battery' | translate }}[kWh]</label>

      <ui-switch size="small" [(ngModel)]="showIndependence"
                 checked="showIndependence" (change)="toggleDataSeries('independence')" color="gray"></ui-switch>
      <label class="slabel">{{'typical-day.independence' | translate }}(%)</label>
    </div>
  </div>

  <!--  container of graph-->
  <div class="graph-container row">
    <div class="col-12 d-flex justify-content-center">
      <div class="graph">
        <div class=" row all-season">
          <div class="col d-flex justify-content-end">
            <kendo-dropdownlist class="kendo"
                                [data]="listItems"
                                textField="text"
                                valueField="value"
                                [valuePrimitive]="true"
                                (valueChange) = "changeSeason()"
                                [(ngModel)]="season">
            </kendo-dropdownlist>
          </div>
        </div>
        <canvas baseChart #baseChart="base-chart" style="margin: auto"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="lineChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
                [plugins]="lineChartPlugins">
        </canvas>
      </div>

    </div>

  </div>
  <!-- container of mobile graph-->

</section>
