import { Component, OnInit } from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {HomeService} from "../home/home.service";
import {BsModalRef} from "ngx-bootstrap/modal";
import {Constants} from '../../app.constant';

@Component({
  selector: 'app-quotev2',
  templateUrl: './quotev2.component.html',
  styleUrls: ['./quotev2.component.scss']
})
export class Quotev2Component implements OnInit {

  customercontact = {
    customerinfo: {
      companyname: '',
      firstname: '',
      lastname: '',
      emailid: '',
      phonenumbers: '',
      projectaddress: '',
      country: '',
      zip: '',
      city: '',
      heatpump: false,
      station: false,
      energymanagment: false,
      allow: false,
      message: '',
    }
  };
  onClose: any;
  customerData;
  email;
  leadId;
  constructor(private appConstants: Constants,
              private toastr: ToastrService,
              private homeService: HomeService,
              public bsModalRef: BsModalRef ) { this.appConstants.leadId.subscribe(value => {
    this.leadId = value as string;
  });
                                                this.appConstants.email.subscribe(value => {
      this.email = value as string;
    }); }

  ngOnInit(): void {
    this.getCustomerInfo();
  }
  confirm() {
    if (this.validateRequest()) {
      this.customercontact['email'] = this.email;
      this.customercontact['lead_id'] = this.leadId;
      this.onClose({
        status: 'CONFIRMED',
        data: {
          customercontact: this.customercontact
        }
      });
    }
  }
  getCustomerInfo() {
    this.homeService.getCustomerInfo({
      customercontact:
        {
          email: this.email,
          lead_id: this.leadId,
          customerinfo: {check: 'info'}
        }
    }).subscribe( res => {
      const response = res as any;
      this.customerData = response;
      this.customercontact.customerinfo.firstname = this.customerData.customerinfo.firstname;
      this.customercontact.customerinfo.lastname = this.customerData.customerinfo.lastname;
      this.customercontact.customerinfo.phonenumbers = this.customerData.customerinfo.phonenumbers;
      this.customercontact.customerinfo.emailid = this.customerData.customerinfo.emailid;
      this.customercontact.customerinfo.companyname = this.customerData.customerinfo.companyname;
      this.customercontact.customerinfo.projectaddress = this.customerData.customerinfo.projectaddress;
      this.customercontact.customerinfo.zip = this.customerData.customerinfo.zip;
      this.customercontact.customerinfo.country = this.customerData.customerinfo.country;
      this.customercontact.customerinfo.city = this.customerData.customerinfo.city;
      this.customercontact.customerinfo.heatpump = this.customerData.customerinfo.heatpump;
      this.customercontact.customerinfo.station = this.customerData.customerinfo.station;
      this.customercontact.customerinfo.energymanagment = this.customerData.customerinfo.energymanagment;
      this.customercontact.customerinfo.allow = this.customerData.customerinfo.allow;
      this.customercontact.customerinfo.message = this.customerData.customerinfo.message;
    })
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  check() {
    // tslint:disable-next-line:max-line-length
    if (this.customercontact.customerinfo.firstname && this.customercontact.customerinfo.lastname && this.customercontact.customerinfo.phonenumbers && this.customercontact.customerinfo.emailid && this.customercontact.customerinfo.country && this.customercontact.customerinfo.allow) {
      return false;
    } else {
      return true;
    }
  }
  validateRequest() {
    console.log( )
    // tslint:disable-next-line:max-line-length
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.customercontact.customerinfo.firstname === '' ||  this.customercontact.customerinfo.firstname === undefined) {
      this.toastr.warning('Please enter your First Name');
      return false;
    } else if (this.customercontact.customerinfo.lastname === '' || this.customercontact.customerinfo.lastname === undefined) {
      this.toastr.warning('Please enter your Last Name');
      return false;
    } else if (this.customercontact.customerinfo.phonenumbers === '' || this.customercontact.customerinfo.phonenumbers === undefined) {
      this.toastr.warning('Please enter your Phone number ');
      return false;
    } else if (this.customercontact.customerinfo.emailid === '' || this.customercontact.customerinfo.emailid === undefined) {
      this.toastr.warning('Please enter your Email ');
      return false;
    } else if (this.customercontact.customerinfo.country === '' || this.customercontact.customerinfo.country === undefined) {
      this.toastr.warning('Please enter your Country');
      return false;
      // tslint:disable-next-line:max-line-length
    } else if (!isNaN(Number(this.customercontact.customerinfo.phonenumbers.trim())) && (this.customercontact.customerinfo.phonenumbers.trim().length > 12 || this.customercontact.customerinfo.phonenumbers.trim().length < 6)) {
      this.toastr.warning('Please enter the valid Phone number');
      return false;
      // tslint:disable-next-line:max-line-length
    } else if (this.customercontact.customerinfo.emailid !== undefined && isNaN(Number(this.customercontact.customerinfo.emailid .trim())) && !emailRegex.test(String(this.customercontact.customerinfo.emailid ).toLowerCase())) {
      this.toastr.warning('Please enter the valid Email address');
      return false;
    } else {
      return true;
    }
  }
}
