<div class="container sustain">
  <p class="title-header h5 mb-2 ml-1 mt-3">{{'sustainability.content' | translate}}</p>

  <!--// Hide the radio button for storage-->
  <!--<div class="row ml-1 mt-3 radio-btn  flex-column">-->
  <!--<div class="radio-btn1 d-flex">-->
  <!--<div class="radio-btn1 d-flex">-->
  <!--<label class="radio-button">-->
  <!--<input type="radio" class="radio-button__input" [(ngModel)]="storage" value="PV-without-storage"-->
  <!--name="PV-select"-->
  <!--(change)="changeSeason()" >-->
  <!--<span class="radio-button__control"></span>-->
  <!--<span class="radio-button__label" style="color: #677171;font-family: 'poppins';">{{'pv-without-storage' | translate }}</span>-->
  <!--</label>-->
  <!--&lt;!&ndash;<img class="ml-0 mb-2" tooltip="Photovoltaic system without battery"&ndash;&gt;-->
  <!--&lt;!&ndash;src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZDVkNWQ1OwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9ImluZm9faWNvbiIgY2xhc3M9ImNscy0xIiBkPSJNMTAtLjAwNWExMCwxMCwwLDEsMCwxMCwxMEExMCwxMCwwLDAsMCwxMC0uMDA1Wm0yLjA4MiwxNS41cS0wLjc3MS4zLTEuMjMyLDAuNDY0YTMuMjQ3LDMuMjQ3LDAsMCwxLTEuMDY4LjE2LDIuMTM3LDIuMTM3LDAsMCwxLTEuNDU0LS40NTZBMS40NzUsMS40NzUsMCwwLDEsNy44MDYsMTQuNWE0LjI0LDQuMjQsMCwwLDEsLjAzOC0wLjU1OGMwLjAyNi0uMTg5LjA2OC0wLjQsMC4xMjUtMC42NDJsMC42NDQtMi4yNzVjMC4wNTctLjIyLjEwNi0wLjQyNywwLjE0NS0wLjYyMWEyLjc1MywyLjc1MywwLDAsMCwuMDU3LTAuNTM2LDAuODI0LDAuODI0LDAsMCwwLS4xNzktMC42MDdBMS4wMTgsMS4wMTgsMCwwLDAsNy45NDcsOS4xYTEuOCwxLjgsMCwwLDAtLjUxMi4wNzZjLTAuMTc0LjA1My0uMzI1LDAuMS0wLjQ0OCwwLjE0OWwwLjE3LS43UTcuNzksOC4zNjEsOC4zNjgsOC4xNzhBMy41NzcsMy41NzcsMCwwLDEsOS40Niw3Ljk5M2EyLjA5MSwyLjA5MSwwLDAsMSwxLjQzMy40NDksMS40OTIsMS40OTIsMCwwLDEsLjUsMS4xNjVxMCwwLjE0OS0uMDM1LjUyM2EzLjUyOCwzLjUyOCwwLDAsMS0uMTI5LjY4N2wtMC42NDEsMi4yNjlxLTAuMDc4LjI3NC0uMTQxLDAuNjIzYTMuMjY2LDMuMjY2LDAsMCwwLS4wNjIuNTMyLDAuNzYzLDAuNzYzLDAsMCwwLC4yLjYxNiwxLjEzMywxLjEzMywwLDAsMCwuNy4xNjQsMS45OTMsMS45OTMsMCwwLDAsLjUzMS0wLjA4MywyLjkzNCwyLjkzNCwwLDAsMCwuNDI4LTAuMTQ0Wm0tMC4xMTMtOS4yMWExLjUzLDEuNTMsMCwwLDEtMS4wOC40MTZBMS41NDgsMS41NDgsMCwwLDEsOS44LDYuMjg0YTEuMzU5LDEuMzU5LDAsMCwxLDAtMi4wMjMsMS41MzUsMS41MzUsMCwwLDEsMS4wODQtLjQyMSwxLjUxOCwxLjUxOCwwLDAsMSwxLjA4LjQyMUExLjM2NCwxLjM2NCwwLDAsMSwxMS45NjQsNi4yODRaIi8+Cjwvc3ZnPgo=">&ndash;&gt;-->
  <!--<span class="ml-0 mt-1" ><i class="fas fa-info-circle fa-2x infoIcon" tooltip="Photovoltaic system without battery"  [ngClass]="{'active' : storage === 'PV-without-storage'}"></i></span>-->
  <!--</div>-->
  <!--<div class="radio-btn1 d-flex ml-5">-->
  <!--<label class="radio-button">-->
  <!--<input type="radio" class="radio-button__input" [(ngModel)]="storage" value="PV-with-storage"-->
  <!--name="PV-select"-->
  <!--(change)="changeSeason()" >-->
  <!--<span class="radio-button__control"></span>-->
  <!--<span class="radio-button__label" style="color: #677171; font-family: 'poppins';">{{'PV-with-storage' | translate }}</span>-->
  <!--</label>-->
  <!--&lt;!&ndash;<img class="ml-1 mb-2" tooltip="Photovoltaic system with battery"&ndash;&gt;-->
  <!--&lt;!&ndash;src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZDVkNWQ1OwogICAgICAgIGZpbGwtcnVsZTogZXZlbm9kZDsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHBhdGggaWQ9ImluZm9faWNvbiIgY2xhc3M9ImNscy0xIiBkPSJNMTAtLjAwNWExMCwxMCwwLDEsMCwxMCwxMEExMCwxMCwwLDAsMCwxMC0uMDA1Wm0yLjA4MiwxNS41cS0wLjc3MS4zLTEuMjMyLDAuNDY0YTMuMjQ3LDMuMjQ3LDAsMCwxLTEuMDY4LjE2LDIuMTM3LDIuMTM3LDAsMCwxLTEuNDU0LS40NTZBMS40NzUsMS40NzUsMCwwLDEsNy44MDYsMTQuNWE0LjI0LDQuMjQsMCwwLDEsLjAzOC0wLjU1OGMwLjAyNi0uMTg5LjA2OC0wLjQsMC4xMjUtMC42NDJsMC42NDQtMi4yNzVjMC4wNTctLjIyLjEwNi0wLjQyNywwLjE0NS0wLjYyMWEyLjc1MywyLjc1MywwLDAsMCwuMDU3LTAuNTM2LDAuODI0LDAuODI0LDAsMCwwLS4xNzktMC42MDdBMS4wMTgsMS4wMTgsMCwwLDAsNy45NDcsOS4xYTEuOCwxLjgsMCwwLDAtLjUxMi4wNzZjLTAuMTc0LjA1My0uMzI1LDAuMS0wLjQ0OCwwLjE0OWwwLjE3LS43UTcuNzksOC4zNjEsOC4zNjgsOC4xNzhBMy41NzcsMy41NzcsMCwwLDEsOS40Niw3Ljk5M2EyLjA5MSwyLjA5MSwwLDAsMSwxLjQzMy40NDksMS40OTIsMS40OTIsMCwwLDEsLjUsMS4xNjVxMCwwLjE0OS0uMDM1LjUyM2EzLjUyOCwzLjUyOCwwLDAsMS0uMTI5LjY4N2wtMC42NDEsMi4yNjlxLTAuMDc4LjI3NC0uMTQxLDAuNjIzYTMuMjY2LDMuMjY2LDAsMCwwLS4wNjIuNTMyLDAuNzYzLDAuNzYzLDAsMCwwLC4yLjYxNiwxLjEzMywxLjEzMywwLDAsMCwuNy4xNjQsMS45OTMsMS45OTMsMCwwLDAsLjUzMS0wLjA4MywyLjkzNCwyLjkzNCwwLDAsMCwuNDI4LTAuMTQ0Wm0tMC4xMTMtOS4yMWExLjUzLDEuNTMsMCwwLDEtMS4wOC40MTZBMS41NDgsMS41NDgsMCwwLDEsOS44LDYuMjg0YTEuMzU5LDEuMzU5LDAsMCwxLDAtMi4wMjMsMS41MzUsMS41MzUsMCwwLDEsMS4wODQtLjQyMSwxLjUxOCwxLjUxOCwwLDAsMSwxLjA4LjQyMUExLjM2NCwxLjM2NCwwLDAsMSwxMS45NjQsNi4yODRaIi8+Cjwvc3ZnPgo=">&ndash;&gt;-->
  <!--<span class="ml-0 mt-1" ><i class="fas fa-info-circle fa-2x infoIcon" tooltip="Photovoltaic system with battery" [ngClass]="{'active' : storage === 'PV-with-storage'}"></i></span>-->

  <!--</div>-->

  <!--</div>-->


  <!--</div>-->
  <!--<div class="row mobile_btn  mb-3 mt-4">-->
  <!--<div class="col-12 w-100">-->
  <!--<select id="pv" (change)="changeSeason()" [(ngModel)]="storage" name="pv">-->
  <!--<option value="PV-without-storage" style="color: #677171; font-family: 'poppins';">{{'pv-without-storage' | translate }}</option>-->
  <!--<option value="PV-with-storage" style="color: #677171; font-family: 'poppins';">{{'PV-with-storage' | translate }}</option>-->
  <!--</select>-->

  <!--</div>-->
  <!--</div>-->



  <!--// All card to show content-->
  <div class="row all-season">
    <div class="cards-list mt-3 mb-4">

      <div class="card card-color">
        <div class="row mx-auto d-flex mt">
          <img src="./assets/sustain/earth.png">
        </div>
        <div class="title title-color">
          <p>{{'sustainability.globe' | translate}}  <span class="value">{{autoKM}}</span>  {{'sustainability.globe1' | translate}}</p>
        </div>
      </div>

      <div class="card card-color">
        <div class="row mx-auto d-flex mt">
          <img src="./assets/sustain/foot.png">
        </div>
        <div class="title title-color">
          <p>{{'sustainability.foot' | translate}} <span class="value">{{co2}}%.</span></p>
        </div>
      </div>

      <div class="card card-color">
        <div class="row mx-auto d-flex mt">
          <img src="./assets/sustain/plant.png">
        </div>
        <div class=" title title-color ">
          <p>{{'sustainability.tree' | translate}} <span class="value">{{trees}}</span> {{'sustainability.tree1' | translate}}</p>
        </div>
      </div>

    </div>
  </div>
  <!--<div class="row">-->
  <!--<div class="col-12">-->
  <!--<div class="next-button mb-3 float-right">-->
  <!--<b type="button" class="text-uppercase mr-2" style="cursor: pointer" (click)="next()">NEXT</b>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
</div>
