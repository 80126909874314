import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {HomeService} from "../home/home.service";
import {NgxSpinnerService} from "ngx-spinner";
import {HeaderModel} from "../homev2/homev2.model";
import {Constants} from '../../app.constant';

declare function esriAngularMap(userName, Pid, accessToken, lng, lat, add): any;
declare function screenShotV2(): any;
@Component({
  selector: 'app-roofareav2',
  templateUrl: './roofareav2.component.html',
  styleUrls: ['./roofareav2.component.scss']
})
export class Roofareav2Component implements OnInit {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  // @Output() onSubmit1: EventEmitter<any> = new EventEmitter<any>();
  @Output() Details: EventEmitter<any> = new EventEmitter<any>();
  latitude;
  areaEvent;
  variable = false;
  longitude;
  address;
  show = false;
  searchLocation;
  accessToken;
  email;
  data;
  leadId: string;
  location: any;
  mapShow: false;
  image;
  public model = new HeaderModel();

  constructor(private toastr: ToastrService,
              private homeService: HomeService,
              private appConstants: Constants,
              private Zone: NgZone,
              private loader: NgxSpinnerService
  ) {
    // @ts-ignore
    window?.angularComponentRef = {component: this, zone: Zone};
    this.appConstants.accessToken.subscribe(value => {
      this.accessToken = value as string;
      console.log(value);
    });
    this.appConstants.email.subscribe(value => {
      this.email = value as string;
    });
    this.appConstants.searchLocation.subscribe(value => {
      this.searchLocation = value as string;
    });
    this.latitude = localStorage.getItem('longitude');
    this.longitude = localStorage.getItem('latitude');
    this.address = localStorage.getItem('address');
  }

  @Input() value;
  origin = localStorage.getItem('origin');
  // latitude = localStorage.getItem('latitude');
  // longitude = localStorage.getItem('longitude');
  // address = localStorage.getItem('address');
  // token = localStorage.getItem('token');
  ngOnInit(): void {
    this.appConstants.reloader.subscribe(res => {
      if (res === true) {
        console.log('getting data from api');
        this.newLeadId();
      }
    });
    this.newLeadId();
  }

  newLeadId() {
    this.homeService.makeNewLead({
      email: this.email,
      address: this.searchLocation
    }).subscribe(res => {
      const response = res;
      this.data = response;
      this.model.leadId = this.data.lead_id;
      this.appConstants.setLeadId(this.model.leadId);
      // this.appConstants.setToken(this.accessToken)
      console.log(this.accessToken);
      esriAngularMap(this.email, this.model.leadId, this.accessToken, this.longitude, this.latitude, this.searchLocation);
    }, error => {
      this.show = false;
      this.toastr.warning('LeadID not present');
    });
  }
  // ask
  b64toBlob(dataURI) {

    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  ss() {
    let name;
    let blob;
    name = screenShotV2();
    name.zoom = 21;
    name.takeScreenshot({
      format: 'jpg',
      quality: 70
    }).then(r => {
      this.image = r.dataUrl;
      localStorage.setItem('mapScreenShotURL', r.dataUrl);
      blob = this.b64toBlob(this.image);
      this.image = new File([blob], 'fileName.jpeg', { type: '\'image/jpeg\''});
    });
  }

  public submitForm(): void {
    // this.onSubmit1.emit(uploadMapData());
    this.ss();
    this.onSubmit.emit({
      value: 'Roof Area',
    });
    this.Details.emit({
      email: this.email,
      lead: this.model.leadId,
      token: this.accessToken,
      location: this.searchLocation
    });
    // const data =  {
    //   // value: 'Roof Area',
    //   // email: this.email,
    //   // lead_id: this.leadId,
    //   // accessToken: this.accessToken
    // };
    // this.onSubmit.emit('Roof Area');
    // console.log(data);

  }
  showLoader() {
  this.loader.show();
  }

  hideLoader() {
  this.loader.hide();
  }
}
