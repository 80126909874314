import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-house-pitch',
  templateUrl: './house-pitch.component.html',
  styleUrls: ['./house-pitch.component.scss']
})
export class HousePitchComponent implements OnInit, OnChanges  {

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() pitchStep;

  @Input() roofPitch;
  @Input() roofType;

  constructor(private  toastr: ToastrService) {

  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.roofType.currentValue, 'changessss');
    if (changes.roofType.currentValue) {
      this.roofPitch = 0;
    }

  }
  selectPitch(value): void {
    this.roofPitch = value;
  }

  pitchCheck() {
    if (this.roofPitch > 90 || this.roofPitch < 0) {
      this.toastr.warning('Pitch can not be greater than 90 and less than 0');
      if (this.roofPitch < 0) {
        this.roofPitch = 0;
        return false;
      }
      if (this.roofPitch > 90) {
        this.roofPitch = 90;
        return false;
      }
      return false;
    }
  }

  public submitForm(): void {
    this.onSubmit.emit({
      value: this.roofPitch
    });
  }

}
