import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {TermsAndConditionComponent} from './terms-and-condition/terms-and-condition.component';
// import { MetaGuard } from '@ngx-meta/core';
import {ResultComponent} from './result/result/result.component';
import {AddressComponent} from './address/address.component';
import {NewResultComponent} from './new-result/new-result.component';
import {ServiceErrorComponent} from './service-error/service-error.component';
import {Roofareav3Component} from "./roofareav3/roofareav3.component";
import {Housetypev3Component} from "./housetypev3/housetypev3.component";
import {Motivationv3Component} from "./motivationv3/motivationv3.component";
import {Consumptionv3Component} from "./consumptionv3/consumptionv3.component";
import {Heaterv3Component} from "./heaterv3/heaterv3.component";
import {Hotwaterv3Component} from "./hotwaterv3/hotwaterv3.component";
import {Orientationv3Component} from "./orientationv3/orientationv3.component";
import {Roofpitchv3Component} from "./roofpitchv3/roofpitchv3.component";
import {HeaderComponent} from './header/header.component';
import {Locationv3Component} from "./locationv3/locationv3.component";
import {Quotev3Component} from "./quotev3/quotev3.component";
import {Independencev3Component} from './resultv3/independencev3/independencev3.component';
import {Resultv3Component} from './resultv3/resultv3.component';
import {AddressInComponent} from './india/address-in/address-in.component';
import {HomeInComponent} from './india/home-in/home-in.component';
import {DeModule} from './de/de.module';
import {ResultInComponent} from './india/result-in/result-in.component';
const routes: Routes = [
  {
    path: '',
    component : AddressComponent,
    pathMatch : 'full'
  },
  {
    path: 'br/V1',
    component : AddressComponent,
    pathMatch : 'full'
  },
  {
    path: 'br/v1',
    component : AddressComponent,
    pathMatch : 'full'
  },
  {
    path: 'br/V2',
    component: HeaderComponent,
    pathMatch: 'full'
  },
  {
    path: 'br/v2',
    component: HeaderComponent,
    pathMatch: 'full'
  },
  {
    path: 'in',
    component:  AddressInComponent,
    pathMatch: 'full'
  },
  {
    path: 'in/V1',
    component:  AddressInComponent,
    pathMatch: 'full'
  },
  {
    path: 'in/v1',
    component:  AddressInComponent,
    pathMatch: 'full'
  },
  {
    path: 'br/home',
    component : HomeComponent,
    pathMatch : 'full'
  },
  {
    path: 'home-in',
    component : HomeInComponent,
    pathMatch : 'full'
  },
  {
    path: 'br/privacy-policy',
    component : PrivacyPolicyComponent,
    pathMatch : 'full'
  },
  {
    path: 'br/terms-and-condition',
    component : TermsAndConditionComponent,
    data: {
      meta: {
        title: 'Sweet home',
        description: 'Home, home sweet home... and what?'
      }
    },
    pathMatch : 'full'
  },
  {
    path: 'de',
    canActivate: [],
    loadChildren: './de/de.module#DeModule',
    pathMatch: 'full'
  },
  {
    path: 'br/result',
    component: NewResultComponent,
  },
  {
    path: 'resultIn',
    component: ResultInComponent,
  },
  {
    path: 'br/resultv3',
    component: Resultv3Component
  },
  {
    path: 'service-error',
    component: ServiceErrorComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
