<div>
  <!--<h3 class="step-heading">{{'Roof-area.label' | translate }}</h3>-->
  <div class="d-flex head">
    <h3 class="step-heading">{{'Roof-area.label' | translate }}</h3>
    <p class="float-right" style="color: gray; font-family: 'poppins';">{{'step' | translate }} 1/7</p>
  </div>
  <div class=" d-flex flex-column">
    <p style=" font-family: 'poppins';">{{'Roof-area.content' | translate }} [<span class='fas fa-pen'>].</span></p>
  </div>
  <br>
  <div class="text-center">
    <img class="animated-geo-image" src="../../assets/roofgif.gif" alt="">
  </div>
  <div class="spacer-3"></div>
  <div class="form-container">
    <div class="spacer-2"></div>
    <p class="text-center">{{'Roof-area.Manual-label' | translate }}</p>
    <div class="spacer-2"></div>
    <div class="row form-group ">
      <div class="col-12">
        <input type="number" class="form-control w-100" [(ngModel)]="roofArea"
               placeholder="{{'Roof-area.Usable-area' | translate }}(m²)"  (mousewheel)="$event.preventDefault()">
      </div>
    </div>
    <div class="text-center">
      <button class="btn btn-primary w-100" (click)="submitForm()">{{'Continue' | translate }}</button>
    </div>
  </div>
</div>
