import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-heaterv3',
  templateUrl: './heaterv3.component.html',
  styleUrls: ['./heaterv3.component.scss']
})
export class Heaterv3Component implements OnInit {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedData: EventEmitter<any> = new EventEmitter<any>();
  selectedHeaterEquipment;
  constructor(private toastr: ToastrService) { }

  ngOnInit(): void {
  }
  selectOption(value) {
    if (this.selectedHeaterEquipment == value){
      this.selectedHeaterEquipment = '';
    }else{
    this.selectedHeaterEquipment = value;
    }
  }
  submitForm() {
    if (this.selectedHeaterEquipment){
    this.onSubmit.emit({
      value: 'Heater'
    });
    this.selectedData.emit({
      value: this.selectedHeaterEquipment
    });
  }else{
      this.toastr.warning('Please select an option first!')
    }
  }
}
